/**
 * This file contains all the english translations for the keys used in the application
 */
export default {
  /* OTHERS */
  ADD: "Add",
  ADD_NEW_OPTION: "Add '{{inputValue}}'",
  AND: "And",
  APPLICABLE: "Applicable",
  BACK_TO_HOME: "Back to home",
  BACK_TO_DPCR_HOME: "Back to DPCR Home",
  CANCEL: "Cancel",
  CLOSE: "Close",
  CONFIRM: "Confirm",
  COPY: "Copy",
  DEMO: "Demo",
  DETAILS: "Details",
  EDIT: "Edit",
  ERROR_404: "This page does not exist.",
  FROM: "From",
  INFERIOR: "Less than",
  INSUFFICIENT_USER_RIGHTS:
    "Insufficient user rights (required group : {{neededGroup}})",
  LIST_MORE_ITEMS_zero: "{{count}} other",
  LIST_MORE_ITEMS_one: "{{count}} other",
  LIST_MORE_ITEMS_other: "{{count}} others",
  NAVIGATE_BACK: "Back",
  NEXT: "Next",
  NEW: "New",
  OPEN: "Open",
  OR: "or",
  PASTE: "Paste",
  PERCENTAGE_RESULT: "{{value}}%",
  PREVIOUS: "Previous",
  REMOVE: "Remove",
  SAVE: "Save",
  STEP_INDEX: "Step {{index}}",
  TIME_DAY_RESULT: "{{value}}d",
  /* ADMINISTRATION_HOME */
  ADMINISTRATION_HOME_TITLE_DESCRIPTION: "Administration",
  ADMINISTRATION_HOME_TITLE: "Administration",
  ADMINISTRATION_HOME_CARD_USERS_TITLE: "Users",
  ADMINISTRATION_HOME_CARD_USERS_SUBTITLE: "Manage users",
  /* ADMINISTRATION_USERS */
  ADMINISTRATION_USERS_TITLE_DESCRIPTION:
    "Administration - list of team members",
  ADMINISTRATION_USERS_TITLE: "List of team members",
  ADMINISTRATION_USERS_ADD_NEW_USER_BUTTON: "Add member",
  ADMINISTRATION_USERS_FILTER_TABS_LABEL:
    "Access to the management of members rights",
  ADMINISTRATION_USERS_FILTER_MAIN_INFORMATIONS: "Main informations",
  ADMINISTRATION_USERS_FILTER_USERS_RIGHTS: "Users rights",
  ADMINISTRATION_USERS_TABLE_HEADER_TITLE: "All users",
  ADMINISTRATION_USERS_TABLE_HEADER_BADGE_zero: "{{count}} member",
  ADMINISTRATION_USERS_TABLE_HEADER_BADGE_one: "{{count}} member",
  ADMINISTRATION_USERS_TABLE_HEADER_BADGE_other: "{{count}} members",
  ADMINISTRATION_USERS_FILTER_NAME_LABEL: "Name",
  ADMINISTRATION_USERS_FILTER_NAME_NO_OPTION_MESSAGE: "No user found",
  ADMINISTRATION_USERS_FILTER_DATE_LABEL: "Creation date",
  ADMINISTRATION_USERS_FILTER_ROLE_LABEL: "Role",
  ADMINISTRATION_USERS_FILTER_ROLE_NO_OPTION_MESSAGE: "No role found",
  ADMINISTRATION_USERS_FILTER_LABORATORY_LABEL: "Laboratory",
  ADMINISTRATION_USERS_FILTER_LABORATORY_NO_OPTION_MESSAGE:
    "No laboratory found",
  ADMINISTRATION_USERS_FILTER_RESET_LABEL: "Reset filters",
  ADMINISTRATION_USERS_TABLE_HEADER_FILTER_GROUP_LABEL: "Filter by group",
  ADMINISTRATION_USERS_TABLE_HEADER_FILTER_GROUP_PLACEHOLDER: "Select group",
  ADMINISTRATION_USERS_TABLE_COLUMN_NAME: "Name",
  ADMINISTRATION_USERS_TABLE_COLUMN_NAME_ENABLED_TOOLTIP: "User enabled",
  ADMINISTRATION_USERS_TABLE_COLUMN_NAME_DISABLED_TOOLTIP: "User disabled",
  ADMINISTRATION_USERS_TABLE_COLUMN_CREATION_DATE: "Creation date",
  ADMINISTRATION_USERS_TABLE_COLUMN_GEENG_ROLE: "Role",
  ADMINISTRATION_USERS_TABLE_COLUMN_LABORATORY: "Laboratory",
  ADMINISTRATION_USERS_TABLE_COLUMN_AUTHORIZATION: "Authorizations",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_SUBTITLE:
    "Choose roles for the user",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_DELETE_GROUP_SUCCESS_TOAST_MESSAGE:
    "User's role successfully deleted",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_DELETE_GROUP_ERROR_TOAST_MESSAGE:
    "An error has occurred during the removal of the user's role. Please retry.",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_ADD_GROUP_SUCCESS_TOAST_MESSAGE:
    "User's role successfully added",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_ADD_GROUP_ERROR_TOAST_MESSAGE:
    "An error has occurred during the addition of the user's role. Please retry.",
  ADMINISTRATION_USERS_TABLE_COLUMN_AUTHORIZATION_CELL_MENU_LABEL:
    "Authorizations",
  ADMINISTRATION_USERS_TABLE_COLUMN_AUTHORIZATION_CELL_MENU_SEE_DETAILS_LINK:
    "See in details",
  ADMINISTRATION_USERS_TABLE_COLUMN_AUTHORIZATION_CELL_MENU_EDIT_RIGHTS:
    "Edit rights",
  ADMINISTRATION_USERS_TABLE_COLUMN_AUTHORIZATION_CELL_MENU_ACTIVATE:
    "Activate user",
  ADMINISTRATION_USERS_TABLE_COLUMN_AUTHORIZATION_CELL_MENU_DEACTIVATE:
    "Deactivate user",
  ADMINISTRATION_USERS_TABLE_COLUMN_AUTHORIZATION_BUTTON_LABEL:
    "Actions for user",
  ADMINISTRATION_USERS_TABLE_COLUMN_AUTHORIZATION_ACTIVATE_SUCCESS_TOAST_MESSAGE:
    "User successfully updated",
  ADMINISTRATION_USERS_TABLE_COLUMN_AUTHORIZATION_ACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the update of the user. Please retry.",
  ADMINISTRATION_USERS_TABLE_COLUMN_AUTHORIZATION_MANAGE_GROUPS_TITLE:
    "Manage roles",
  /* ADMINISTRATION USERS CREATION */
  ADMINISTRATION_USERS_CREATION_TITLE_DESCRIPTION: "Create a new user",
  ADMINISTRATION_USERS_CREATION_TITLE: "Create a new user",
  ADMINISTRATION_USERS_CREATION_SUBTITLE:
    "Define here the informations about the user : identity, mail address and the roles granted",
  ADMINISTRATION_USERS_CREATION_FORM_IDENTITY_TITLE: "Identity",
  ADMINISTRATION_USERS_CREATION_FORM_IDENTITY_SUBTITLE:
    "Informations about user's identity",
  ADMINISTRATION_USERS_CREATION_FORM_FIRSTNAME_LABEL: "Firstname",
  ADMINISTRATION_USERS_CREATION_FORM_LASTNAME_LABEL: "Lastname",
  ADMINISTRATION_USERS_CREATION_FORM_USERNAME_LABEL: "Username",
  ADMINISTRATION_USERS_CREATION_FORM_MAIL_TITLE: "Mail address",
  ADMINISTRATION_USERS_CREATION_FORM_MAIL_SUBTITLE:
    "Informations about user's mail address",
  ADMINISTRATION_USERS_CREATION_FORM_MAIL_LABEL: "Mail address",
  ADMINISTRATION_USERS_CREATION_FORM_GROUPS_TITLE: "Roles",
  ADMINISTRATION_USERS_CREATION_FORM_GROUPS_SUBTITLE:
    "Choose the roles granted to the user",
  ADMINISTRATION_USERS_CREATION_FORM_GROUPS_LABEL: "Roles",
  ADMINISTRATION_USERS_CREATION_CANCEL_BUTTON_LABEL: "Cancel",
  ADMINISTRATION_USERS_CREATION_FORM_SUBMIT_BUTTON_LABEL: "Create user",
  ADMINISTRATION_USERS_CREATION_SUCCESS_TOAST_MESSAGE:
    "User successfully created",
  /* ADMINISTRATION USERS ROLES */
  USER_GROUP_ADMIN: "Administrator",
  USER_GROUP_MANAGER: "Manager",
  USER_GROUP_EXPERT_USER: "Expert",
  USER_GROUP_BIO_USER: "Biologist",
  USER_GROUP_TECH_USER: "Technician",
  /* ANALYSES QUALITY RULES */
  ANALYSES_QUALITY_RULES_OUTPUT_PASS: "Pass",
  ANALYSES_QUALITY_RULES_OUTPUT_PASS_LIMIT: "Pass limit",
  ANALYSES_QUALITY_RULES_OUTPUT_LOW: "Low",
  ANALYSES_QUALITY_RULES_OUTPUT_STRONG: "Strong",
  /* ANALYSIS CREATION STEPPER */
  ANALYSES_CREATION_STEPPER_STEP1_TITLE: "Settings",
  ANALYSES_CREATION_STEPPER_STEP2_TITLE: "Matrices",
  ANALYSES_CREATION_STEPPER_STEP3_TITLE: "Quality rules",
  ANALYSES_CREATION_STEPPER_STEP4_TITLE: "DNA quantity calculation",
  ANALYSES_CREATION_STEPPER_STEP5_TITLE: "LoD and LoB",
  ANALYSES_CREATION_STEPPER_STEP6_TITLE: "Allelic Frequency",
  ANALYSES_CREATION_STEPPER_STEP7_TITLE: "Sensitivity",
  ANALYSES_CREATION_STEPPER_STEP8_TITLE: "Uncertainty",
  ANALYSES_CREATION_STEPPER_STEP9_TITLE: "Witnesses validation",
  ANALYSES_CREATION_STEPPER_STEP10_TITLE: "Decision rules",
  ANALYSES_CREATION_STEPPER_SUBTITLE_TODO: "To complete",
  ANALYSES_CREATION_STEPPER_SUBTITLE_CURRENT: "In progress",
  ANALYSES_CREATION_STEPPER_SUBTITLE_DONE: "Validated",
  /* ANALYSIS CREATION */
  ANALYSES_CREATION_NAME_LABEL: "Analysis name",
  ANALYSES_CREATION_TARGETS_COUNT_LABEL: "Targets number",
  ANALYSES_CREATION_CHIP_LABEL: "Chip",
  ANALYSES_CREATION_MATRICES_LABEL: "Matrices",
  ANALYSES_CREATION_ERROR_TOAST_MESSAGE: "An error has occurred. Please retry",
  ANALYSES_CREATION_SUCCESS_TOAST_MESSAGE:
    "Étape {{step}} de la création d'une analyse effectuée avec succès",
  ANALYSES_CREATION_FORM_REPLICATE_LABEL: "Replicate x{{replicate}}",
  ANALYSES_CREATION_FORM_ADD_BUTTON: "Add condition",
  /* ANALYSIS CREATION STEP 1 */
  ANALYSES_CREATION_FORM_STEP1_TITLE: "Analysis creation",
  ANALYSES_CREATION_FORM_STEP1_SUBTITLE:
    "Define here the main informations of the analysis : name, used material (software, kit, chip), if the analysis is in Development mode or directly in Production, as well as the presence or absence of witnesses",
  ANALYSES_CREATION_VALIDATE_STEP_SUBMIT_BUTTON: "Validate informations",
  ANALYSES_CREATION_FORM_STEP1_POSITIVE_CONTROL_LABEL:
    "Applicable positive witnesses",
  ANALYSES_CREATION_FORM_STEP1_CONTROL_PREFIX_PLACEHOLDER: "Sample name prefix",
  ANALYSES_CREATION_FORM_STEP1_CONTROL_PREFIX_TOOLTIP:
    "To indicate that a sample is a {{control}} in the results files, you have 2 options: either enter the prefix of the sample name associated with this type of control in this field (for example: '{{exampleValue}}'), or fill in the SampleType column of the samplesheet with '{{samplesheetValue}}' for this sample.",
  ANALYSES_CREATION_FORM_STEP1_NEGATIVE_CONTROL_LABEL:
    "Applicable negative witnesses",
  ANALYSES_CREATION_FORM_STEP1_BLANK_CONTROL_LABEL:
    "Applicable blank witnesses",
  ANALYSES_CREATION_FORM_STEP1_MODE_LABEL: "Analysis in production",
  ANALYSES_CREATION_FORM_STEP1_MODE_DESCRIPTION:
    "By default the analysis is in development. This status will determine whether it is possible to edit certain fields in the analysis after it has been created",
  ANALYSES_CREATION_FORM_STEP1_NAME_DESCRIPTION:
    "Choose the nomenclature you require",
  ANALYSES_CREATION_FORM_STEP1_SOFTWARE_LABEL: "Software",
  ANALYSES_CREATION_FORM_STEP1_SOFTWARE_DESCRIPTION:
    "Defines the format of results files",
  ANALYSES_CREATION_FORM_STEP1_SOFTWARE_PLACEHOLDER: "Select a software",
  ANALYSES_CREATION_FORM_STEP1_SOFTWARE_NO_OPTION: "No software found",
  ANALYSES_CREATION_FORM_STEP1_VERSION_LABEL: "Version number",
  ANALYSES_CREATION_FORM_STEP1_KIT_LABEL: "Kit",
  ANALYSES_CREATION_FORM_STEP1_KIT_DESCRIPTION: "Defines the analysis targets",
  ANALYSES_CREATION_FORM_STEP1_KIT_PLACEHOLDER: "Select a kit",
  ANALYSES_CREATION_FORM_STEP1_KIT_NO_OPTION: "No kit found",
  ANALYSES_CREATION_FORM_STEP1_CHIP_PLACEHOLDER: "Select a chip",
  ANALYSES_CREATION_FORM_STEP1_CHIP_NO_OPTION: "No chip found",
  ANALYSES_CREATION_FORM_STEP1_SUBMIT: "Create analysis",
  /* ANALYSIS CREATION STEP 2 */
  ANALYSES_CREATION_FORM_STEP2_TITLE: "Definition of analysis matrices",
  ANALYSES_CREATION_FORM_STEP2_SUBTITLE:
    "Define here the matrices (fDNA, cfDNA, etc.) related to the analysis.",
  ANALYSES_CREATION_FORM_STEP2_MATRICES_LABEL: "Matrices list",
  ANALYSES_CREATION_FORM_STEP2_MATRICES_SUBTITLE:
    "Indicate the number of repetitions per matrix. This will have an impact on the following steps.",
  ANALYSES_CREATION_FORM_STEP2_MATRICES_ADD_BUTTON: "Add matrix",
  ANALYSES_CREATION_FORM_STEP2_MATRICES_NAME_LABEL: "Matrix name",
  ANALYSES_CREATION_FORM_STEP2_MATRICES_HEADER_MATRICES: "Matrices",
  ANALYSES_CREATION_FORM_STEP2_MATRICES_HEADER_REPETITIONS: "Repetitions",
  ANALYSES_CREATION_FORM_STEP2_REPETITIONS_PLACEHOLDER:
    "Select one or more repetitions",
  /* ANALYSIS CREATION STEP 3 OPERATORS */
  GREATER: "Greater than",
  LESS_EQUAL: "Less than or equal to ",
  /* ANALYSIS CREATION STEP 3 */
  ANALYSES_CREATION_FORM_STEP3_TITLE:
    "Definition of analysis quality control rules",
  ANALYSES_CREATION_FORM_STEP3_SUBTITLE:
    "Define here the quality control rules for the analysis for each matrix (fDNA, cfDNA, etc.), specifying the criteria and conditions to be met in order to validate the analysis.",
  ANALYSES_CREATION_FORM_STEP3_TABS_LABEL:
    "Select one of the analysis matrices",
  ANALYSES_CREATION_FORM_STEP3_QC_RULE_MATHS_FORMULA_LABEL:
    "Formula of quality control rule",
  ANALYSES_CREATION_FORM_STEP3_QC_RULE_MATHS_FORMULA_MODAL_TITLE:
    "Quality rule - {{matrix}}",
  ANALYSES_CREATION_FORM_STEP3_QC_RULE_CONDITIONS_LABEL: "Quality rules",
  ANALYSES_CREATION_FORM_STEP3_QC_RULE_CUTOFF_LABEL: "Threshold value",
  ANALYSES_CREATION_FORM_STEP3_QC_RULE_OUTPUT_LABEL: "Output value",
  /* ANALYSIS CREATION STEP 4 */
  ANALYSES_CREATION_FORM_STEP4_TITLE:
    "Definition of analysis DNA quantity calculation",
  ANALYSES_CREATION_FORM_STEP4_SUBTITLE:
    "Define here the formula for calculating the amount of DNA in the analysis, specifying the criteria and conditions to be met in order to validate the analysis.",
  ANALYSES_CREATION_FORM_STEP4_QC_RULE_MATHS_FORMULA_LABEL:
    "Formula for calculating the amount of DNA",
  ANALYSES_CREATION_FORM_STEP4_QC_RULE_MATHS_FORMULA_MODAL_TITLE:
    "Rule for calculating the amount of DNA",
  ANALYSES_CREATION_FORM_STEP4_QC_RULE_CONDITIONS_LABEL: "Quality rules",
  ANALYSES_CREATION_FORM_STEP4_QC_RULE_CUTOFF_LABEL: "Threshold value",
  ANALYSES_CREATION_FORM_STEP4_QC_RULE_OUTPUT_LABEL: "Output value",
  /* ANALYSIS CREATION STEP 5 */
  ANALYSES_CREATION_FORM_STEP5_TITLE:
    "Limit of Blank and Limit of Decision definition",
  ANALYSES_CREATION_FORM_STEP5_SUBTITLE:
    "Here you define the thresholds of Limit of Blank (LoB) and Limit of Decision (LoD) for each matrix, replicate and target in the kit associated with the analysis.",
  ANALYSES_CREATION_FORM_STEP5_TABS_LABEL:
    "Select one of the analysis matrices",
  ANALYSES_CREATION_FORM_STEP5_LOB_BADGE_LABEL:
    "LoB {{validated}}/{{expected}}",
  ANALYSES_CREATION_FORM_STEP5_LOD_BADGE_LABEL:
    "LoD {{validated}}/{{expected}}",
  ANALYSES_CREATION_FORM_STEP5_LOB_LABEL: "LoB definition",
  ANALYSES_CREATION_FORM_STEP5_LOD_LABEL: "LoD definition",
  ANALYSES_CREATION_FORM_STEP5_UNDEFINED_PLACEHOLDER: "Not defined",
  ANALYSES_CREATION_FORM_STEP5_LOB_LOD_UNIT: "in cp/μL",
  /* ANALYSIS CREATION STEP 6 */
  ANALYSES_CREATION_FORM_STEP6_TITLE: "Allelic frequency calculation",
  ANALYSES_CREATION_FORM_STEP6_SUBTITLE:
    "Here you define the formulas for calculating the allelic frequency (VAF) for each target in the kit associated with the analysis. You can apply the same formula to other targets if necessary. To do so, use copy and paste buttons next to formula fields. When pasting formula from target A to target B (by using copy and paste buttons), it will automatically replace the target A variables by the ones of the target B.",
  ANALYSES_CREATION_FORM_STEP6_MATHS_FORMULA_MODAL_TITLE:
    "VAF definition - Target n°{{targetIndex}} - {{target}}",
  ANALYSES_CREATION_FORM_STEP6_MATHS_FORMULA_INAPPLICABLE_LABEL:
    "VAF not applicable",
  /* ANALYSIS CREATION STEP 7 */
  ANALYSES_CREATION_FORM_STEP7_TITLE: "Sensitivity calculation",
  ANALYSES_CREATION_FORM_STEP7_SUBTITLE:
    "Here you can define the sensitivity of your dPCR analysis. This parameter is essential for assessing the method's ability to detect low levels of target in a sample. By adjusting the sensitivity, you can determine the minimum detection threshold required, ensuring reliable results even when the target concentration is low.  You can apply the same formula to other targets if necessary. To do so, use copy and paste buttons next to formula fields. When pasting formula from target A to target B (by using copy and paste buttons), it will automatically replace the target A variables by the ones of the target B.",
  ANALYSES_CREATION_FORM_STEP7_MATHS_FORMULA_MODAL_TITLE:
    "Sensitivity definition - Target n°{{targetIndex}} - {{target}}",
  ANALYSES_CREATION_FORM_STEP7_MATHS_FORMULA_INAPPLICABLE_LABEL:
    "Sensitivity not applicable",
  /* ANALYSIS CREATION STEP 8 */
  ANALYSES_CREATION_FORM_STEP8_TITLE: "Uncertainty calculation",
  ANALYSES_CREATION_FORM_STEP8_SUBTITLE:
    "Here you can define the uncertainty of your dPCR analysis. You can apply the same formula to other targets if necessary. To do so, use copy and paste buttons next to formula fields. When pasting formula from target A to target B (by using copy and paste buttons), it will automatically replace the target A variables by the ones of the target B.",
  ANALYSES_CREATION_FORM_STEP8_MATHS_FORMULA_MODAL_TITLE:
    "Uncertainty definition - Target n°{{targetIndex}} - {{target}}",
  ANALYSES_CREATION_FORM_STEP8_MATHS_FORMULA_INAPPLICABLE_LABEL:
    "Uncertainty not applicable",
  /* ANALYSIS CREATION STEP 9 */
  ANALYSES_CREATION_FORM_STEP9_TITLE: "Witnesses control",
  ANALYSES_CREATION_FORM_STEP9_SUBTITLE:
    "Define the control rules associated with the analysis witnesses here. For each type of witness (blank, positive or negative), and each target, you can define your own control rule. You can apply the same formula to other targets if necessary. To do so, use copy and paste buttons next to formula fields. When pasting formula from target A to target B (by using copy and paste buttons), it will automatically replace the target A variables by the ones of the target B.",
  ANALYSES_CREATION_FORM_STEP9_TABS_LABEL:
    "Select a witness type to define its controls for each target",
  ANALYSES_CREATION_FORM_STEP9_TAB_TITLE: "{{control}} control",
  ANALYSES_CREATION_FORM_STEP9_MATHS_FORMULA_MODAL_TITLE:
    "{{control}} - Target n°{{targetIndex}} - {{target}}",
  ANALYSES_CREATION_FORM_STEP9_MATHS_FORMULA_INAPPLICABLE_LABEL:
    "{{control}} not applicable",
  /* ANALYSIS CREATION STEP 10 */
  ANALYSES_CREATION_FORM_STEP10_TITLE: "Decision rules",
  ANALYSES_CREATION_FORM_STEP10_SUBTITLE:
    "Define the decision rules associated with the analysis here. You can first define the interpretation labels for each target, and then for each target, matrix and repetition, you can define your own decision rule and combine the mathematical formulas if required. You can also apply the same formula to other targets if necessary. To do so, use copy and paste buttons next to formula fields. When pasting formula from target A to target B (by using copy and paste buttons), it will automatically replace the target A variables by the ones of the target B.",
  ANALYSES_CREATION_FORM_STEP10_TABS_LABEL:
    "Select a matrix or the interpretations panel",
  ANALYSES_CREATION_FORM_STEP10_TAB_INTERPRETATIONS_TITLE: "Interpretations",
  ANALYSES_CREATION_FORM_STEP10_MATHS_FORMULA_MODAL_TITLE:
    "Decision rule - Target n°{{targetIndex}} - {{target}}",
  ANALYSES_CREATION_FORM_STEP10_INTERPRETATION_TAB_TABLE_TARGET_COLUMN_TITLE:
    "Target",
  ANALYSES_CREATION_FORM_STEP10_INTERPRETATION_TAB_TABLE_POSITIVE_COLUMN_TITLE:
    "Positive",
  ANALYSES_CREATION_FORM_STEP10_INTERPRETATION_TAB_TABLE_NEGATIVE_COLUMN_TITLE:
    "Negative",
  ANALYSES_CREATION_FORM_STEP10_INTERPRETATION_TAB_TABLE_NON_CONTRIBUTIVE_COLUMN_TITLE:
    "Non Contributive",
  ANALYSES_CREATION_FORM_STEP10_INTERPRETATION_TAB_TABLE_APPLICABLE_COLUMN_TITLE:
    "Applicable",
  ANALYSES_CREATION_FORM_STEP10_TARGETS_BADGE_LABEL:
    "{{validated}}/{{expected}}",
  ANALYSES_CREATION_FORM_STEP10_NOT_APPLICABLE_BADGE_LABEL_zero:
    "{{count}} not applicable",
  ANALYSES_CREATION_FORM_STEP10_NOT_APPLICABLE_BADGE_LABEL_one:
    "{{count}} not applicable",
  ANALYSES_CREATION_FORM_STEP10_NOT_APPLICABLE_BADGE_LABEL_other:
    "{{count}} not applicable",
  ANALYSES_CREATION_FORM_STEP10_MATHS_FORMULA_INAPPLICABLE_LABEL:
    "Decision rule not applicable",
  ANALYSES_CREATION_FORM_STEP10_QC_RULE_MATHS_FORMULA_MODAL_TITLE:
    "Decision rule - {{matrix}} - Rep. {{repetition}} - {{target}}",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_TITLE:
    "In this tab, you can define your own association of rules to predefine a sample analysis result as non-contributive. This feature will be available shortly.",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_EXPLANATION:
    "For the time being, here are the rules associated with defining a result as non-contributive.",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_QC_CHIP: "QC Chip",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_QC_CHIP_TOOLTIP:
    "Control rule associated with the chip used in this analysis",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_QC_SAMPLE: "QC Sample",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_QC_SAMPLE_TOOLTIP:
    "Quality control rule defined in step 3 when creating this analysis",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_DNA_AMOUNT: "DNA Amount",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_DNA_AMOUNT_TOOLTIP:
    "Quality control rule linked to the quantity of DNA and defined in step 4 of the creation of this analysis",
  /* ANALYSES CREATION SUMMARY MODAL */
  ANALYSES_CREATION_SUMMARY_MODAL_TITLE: "Summary of your analysis creation",
  ANALYSES_CREATION_SUMMARY_MODAL_INFORMATIONS: "Informations",
  ANALYSES_CREATION_SUMMARY_MODAL_NAME: "Name",
  ANALYSES_CREATION_SUMMARY_MODAL_VERSION: "Version",
  ANALYSES_CREATION_SUMMARY_MODAL_SOFTWARE: "Software",
  ANALYSES_CREATION_SUMMARY_MODAL_KIT: "Kit",
  ANALYSES_CREATION_SUMMARY_MODAL_CHIP: "Chip",
  ANALYSES_CREATION_SUMMARY_MODAL_TARGETS: "Targets number",
  ANALYSES_CREATION_SUMMARY_MODAL_TARGETS_VALUE_zero: "{{count}} target",
  ANALYSES_CREATION_SUMMARY_MODAL_TARGETS_VALUE_one: "{{count}} target",
  ANALYSES_CREATION_SUMMARY_MODAL_TARGETS_VALUE_other: "{{count}} targets",
  ANALYSES_CREATION_SUMMARY_MODAL_MATRICES: "Matrices",
  ANALYSES_CREATION_SUMMARY_MODAL_MODE_LABEL: "Analysis in production",
  ANALYSES_CREATION_SUMMARY_MODAL_MODE_DESCRIPTION:
    "By default the analysis is in development. This status will determine whether it is possible to edit certain fields in the analysis after it has been created",
  /* ANALYSES UPDATE FETCHER */
  ANALYSES_UPDATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the update of the analysis. Please retry.",
  ANALYSES_UPDATE_SUCCESS_TOAST_MESSAGE: "Analysis update successfully done",
  /* ANALYSES DETAILS */
  ANALYSES_DETAILS_ANALYSIS_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Analysis not found. Back to analyses list",
  ANALYSES_DETAILS_ANALYSIS_CANNOT_BE_UPDATED_ERROR_TOAST_MESSAGE:
    "This analysis cannot be updated. Back to analyses list",
  ANALYSES_DETAILS_TITLE: "Informations of the analysis '{{analysisName}}'",
  ANALYSES_DETAILS_DESCRIPTION:
    "Informations of the analysis '{{analysisName}}'",
  ANALYSES_DETAILS_INFORMATIONS_TITLE: "Informations",
  ANALYSES_DETAILS_INFORMATIONS_SUBTITLE:
    "Main informations about the analysis",
  ANALYSES_DETAILS_NAME_LABEL: "Name",
  ANALYSES_DETAILS_KIT_LABEL: "Kit",
  ANALYSES_DETAILS_CHIP_LABEL: "Chip",
  ANALYSES_DETAILS_CREATION_LABEL: "Creation",
  ANALYSES_DETAILS_CREATION_VALUE: "The {{- date}}, by {{user}}",
  ANALYSES_DETAILS_CREATION_DATE_VALUE: "The {{- date}}",
  ANALYSES_DETAILS_LAST_MODIFICATION_LABEL: "Last modification",
  ANALYSES_DETAILS_LAST_MODIFICATION_VALUE: "The {{- date}}, by {{user}}",
  ANALYSES_DETAILS_LAST_MODIFICATION_DATE_VALUE: "The {{- date}}",
  ANALYSES_DETAILS_EDIT_BUTTON_LABEL: "Edit analysis",
  /* ANALYSES LIST */
  BACK_TO_ANALYSES_LIST: "Back to analyses list",
  ANALYSES_LIST_TITLE_DESCRIPTION: "Analyses list",
  ANALYSES_LIST_TITLE: "Analyses list",
  ANALYSES_LIST_HEADER_ALL_ANALYSES: "All analyses",
  ANALYSES_LIST_HEADER_ACTIVE_ANALYSES: "Active analyses",
  ANALYSES_LIST_COUNT_LABEL_zero: "{{count}} analysis",
  ANALYSES_LIST_COUNT_LABEL_one: "{{count}} analysis",
  ANALYSES_LIST_COUNT_LABEL_other: "{{count}} analyses",
  ANALYSES_LIST_SEARCH_ANALYSE_LABEL: "Search analysis",
  ANALYSES_LIST_ADD_NEW_ANALYSE_BUTTON: "Add new analysis",
  ANALYSES_LIST_FILTER_ALL_ANALYSES: "All",
  ANALYSES_LIST_FILTER_ACTIVE_ANALYSES: "Active",
  ANALYSES_LIST_CARD_KIT_LABEL: "Kit",
  ANALYSES_LIST_CARD_MODE_DEV: "Development",
  ANALYSES_LIST_CARD_MODE_PROD: "Production",
  ANALYSES_LIST_CARD_CHIP_LABEL: "Chip",
  ANALYSES_LIST_CARD_VERSION_LABEL: "Version",
  ANALYSES_LIST_CARD_IS_ACTIVE: "Active",
  ANALYSES_LIST_CARD_IS_INACTIVE: "Archived",
  ANALYSES_LIST_CARD_SEE_DETAILS_LINK: "See in détails",
  ANALYSES_LIST_CARD_MENU_NEW_VERSION: "New version",
  ANALYSES_LIST_CARD_MENU_ACTIVATE: "Activate",
  ANALYSES_LIST_CARD_MENU_DEACTIVATE: "Archive",
  ANALYSES_LIST_CARD_MENU_UPDATE: "Edit",
  ANALYSES_LIST_CARD_MENU_DELETE: "Delete",
  ANALYSES_LIST_CARD_DELETION_MODAL_TITLE:
    "Deletion of analysis\n'{{analysisName}}'",
  ANALYSES_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Are you sure you want to remove the analysis '{{analysisName}}' ?",
  ANALYSES_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Remove analysis",
  ANALYSES_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Analysis removed successfully",
  ANALYSES_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the removal of the analysis. Please retry.",
  ANALYSES_LIST_ACTIVATE_SUCCESS_TOAST_MESSAGE:
    "Analysis activated successfully",
  ANALYSES_LIST_ACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the activation of the analysis. Please retry.",
  ANALYSES_LIST_DEACTIVATE_SUCCESS_TOAST_MESSAGE:
    "Analysis deactivated successfully",
  ANALYSES_LIST_DEACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the deactivation of the analysis. Please retry.",
  ANALYSES_LIST_CARD_NEW_VERSION_MODAL_TITLE:
    "Creation of the new version of the analysis\n'{{analysisName}}'",
  ANALYSES_LIST_CARD_NEW_VERSION_MODAL_SUBTITLE:
    "You are about to create a new version of the analysis '{{analysisName}}'. Enter the new version number and confirm.",
  ANALYSES_LIST_CARD_NEW_VERSION_MODAL_FORM_NEW_VERSION_LABEL: "New version",
  ANALYSES_LIST_CARD_NEW_VERSION_MODAL_FORM_SUBMIT_BUTTON: "Create new version",
  ANALYSES_LIST_CREATE_NEW_VERSION_SUCCESS_TOAST_MESSAGE:
    "New version of the analysis created successfully",
  ANALYSES_LIST_CREATE_NEW_VERSION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the creation of the analysis. Please retry.",
  /* APP HOME */
  APP_HOME_TITLE_DESCRIPTION: "Geeng Application",
  APP_HOME_TITLE: "Applications portal",
  APP_HOME_WELCOME: "Welcome, {{user}}",
  APP_HOME_TABS_LABEL:
    "Consult the list of applications or find out about the latest news",
  APP_HOME_TABS_APPLICATIONS: "Applications",
  APP_HOME_TABS_LATEST: "Latest",
  APP_HOME_APPLICATION_DPCR: "dPCR - Onco",
  APP_HOME_APPLICATION_DPCR_SUBTITLE: "Stilla",
  APP_HOME_APPLICATION_NGS_VIRO: "NGS - Viro",
  APP_HOME_APPLICATION_NGS_VIRO_SUBTITLE: "QIAGEN",
  APP_HOME_APPLICATION_NGS_ONCO: "NGS - Onco",
  APP_HOME_APPLICATION_NGS_ONCO_SUBTITLE: "FDGM",
  APP_HOME_APPLICATION_NGS_PRENAT: "NGS - Prenatal",
  APP_HOME_APPLICATION_NGS_PRENAT_SUBTITLE: "Genome",
  APP_HOME_APPLICATION_PCR_VIRO: "PCR - Viro",
  APP_HOME_APPLICATION_PCR_VIRO_SUBTITLE: "QIAGEN",
  APP_HOME_APPLICATION_PCR_FER: "PCR - FER",
  APP_HOME_APPLICATION_PCR_FER_SUBTITLE: "LaCAR",
  APP_HOME_SUBTABS_LABEL:
    "Check out the latest features added or the list of the latest news",
  APP_HOME_TABS_FEATURES: "Features",
  APP_HOME_TABS_NEWS: "News",
  APP_HOME_FEATURE_DPCR_ANALYSIS_CREATION: "Analysis creation path",
  APP_HOME_FEATURE_DPCR_ANALYSIS_CREATION_DETAILS:
    "Users can now create their own analysis. Following each step of the process, they first define the kit/panel and the chip used, then the parameters of their analysis, which will be used to help them interpret the results of the runs using this analysis.",
  APP_HOME_FEATURE_DPCR_KITS_LISTING: "Kits list",
  APP_HOME_FEATURE_DPCR_KITS_LISTING_DETAILS:
    "Users can define their own kits for use in Digital PCR analyses. In particular, they can define the list of targets associated with the kit and then intervene in the kit's life cycle (activation/deactivation, modification, etc.).",
  APP_HOME_FEATURE_DPCR_CHIPS_LISTING: "Chips list",
  APP_HOME_FEATURE_DPCR_CHIPS_LISTING_DETAILS:
    "Users can define their own chips for use in Digital PCR analyses. In particular, they can define the physical parameters of the chip (columns, rows, input and partition volume, etc.) and then intervene in the chip's life cycle (activation/deactivation, modification, etc.).",
  APP_HOME_FEATURE_DPCR_ANALYSES_LISTING: "Analyses list",
  APP_HOME_FEATURE_DPCR_ANALYSES_LISTING_DETAILS:
    "Users can view all the Digital PCR analyses available for their runs. They can then intervene in the analysis life cycle (activation/deactivation, modification, etc.).",
  APP_HOME_FEATURE_DPCR_SERIES_LISTING: "Series list",
  APP_HOME_FEATURE_DPCR_SERIES_LISTING_DETAILS:
    "Users can now consult the list of series completed or in progress and access the results of the associated runs.",
  APP_HOME_FEATURE_DPCR_RUNS_LISTING: "Runs list",
  APP_HOME_FEATURE_DPCR_RUNS_LISTING_DETAILS:
    "Users can consult the runs imported into the application and filter the list using different filters to find the run(s) they are looking for.",
  APP_HOME_FEATURE_DPCR_RUN_QC_VALIDATION: "Run quality controls validation",
  APP_HOME_FEATURE_DPCR_RUN_QC_VALIDATION_DETAILS:
    "Once the run has been imported into the application, the user can validate the run's quality controls. This is the first step before validating the samples contained in the run. If the quality controls of the run are not satisfactory, then the sample results will probably not be contributory.",
  APP_HOME_FEATURE_DPCR_SAMPLES_LISTING: "Series' samples list",
  APP_HOME_FEATURE_DPCR_SAMPLES_LISTING_DETAILS:
    "The user can view the list of samples contained in a series and filter them according to different parameters, to quickly find a desired sample or the number of samples remaining to be validated, for example.",
  APP_HOME_FEATURE_DPCR_SAMPLES_VALIDATION: "Sample validation",
  APP_HOME_FEATURE_DPCR_SAMPLES_VALIDATION_DETAILS:
    "This is one of the application's main functions: the validation of sample analysis results. The user can interpret the results of a sample's analysis from the interface, helping him or her to make interpretation decisions.",
  /* APPLICATIONS */
  APPLICATION_DPCR: "DPCR",
  /* BREADCRUMBS */
  BREADCRUMBS_ADMINISTRATION_USERS: "Members management",
  BREADCRUMBS_ANALYSES: "Analyses",
  BREADCRUMBS_ANALYSES_ID: "Analysis - {{id}}",
  BREADCRUMBS_BATCHES_MANAGEMENT: "Kit batches management",
  BREADCRUMBS_BATCHES_MANAGEMENT_ID: "Kit batch - {{id}}",
  BREADCRUMBS_CHIPS: "Chips",
  BREADCRUMBS_CHIPS_ID: "Chip - {{id}}",
  BREADCRUMBS_CREATE: "Add",
  BREADCRUMBS_DASHBOARDS: "Dashboards",
  BREADCRUMBS_DEFAULT_ID: "ID - {{id}}",
  BREADCRUMBS_EDIT: "Edition",
  BREADCRUMBS_KITS: "Kits",
  BREADCRUMBS_KITS_ID: "Kit - {{id}}",
  BREADCRUMBS_NEW: "Add",
  BREADCRUMBS_NOTIFICATIONS: "Notifications",
  BREADCRUMBS_RUNS: "Runs",
  BREADCRUMBS_SAMPLE_ID: "Sample - {{id}}",
  BREADCRUMBS_SERIES: "Series",
  BREADCRUMBS_SERIES_ID: "Series - {{id}}",
  BREADCRUMBS_SOFTWARES: "Softwares",
  BREADCRUMBS_USERS: "Users",
  BREADCRUMBS_SEARCH_INPUT_LABEL: "Sample research",
  BREADCRUMBS_SEARCH_INPUT_LABEL_NO_OPTION_MESSAGE: "No sample found",
  BREADCRUMBS_SEARCH_INPUT_LABEL_ITEM_PATHOLOGY: "Patho.",
  BREADCRUMBS_SEARCH_INPUT_LABEL_ITEM_TARGETS_zero: "Target",
  BREADCRUMBS_SEARCH_INPUT_LABEL_ITEM_TARGETS_one: "Target",
  BREADCRUMBS_SEARCH_INPUT_LABEL_ITEM_TARGETS_other: "Targets",
  /* CALCULATION */
  VAF: "VAF calculation",
  SENSITIVITY: "Sensitivity calculation",
  UNCERTAINTY: "Uncertainty",
  CALCULATION_TOOLTIP_ERROR:
    "{{type}}: an error has occurred during the calculation of the result",
  /* CHIPS CREATION */
  CHIPS_CREATION_TITLE_DESCRIPTION: "Chip creation",
  CHIPS_CREATION_TITLE: "Chip creation",
  CHIPS_CREATION_SUBTITLE:
    "Define here the chip informations : a name, a supplier, a version number and its physical caracteristics",
  CHIPS_CREATION_SUCCESSFUL_TOAST_MESSAGE: "Chip successfully created",
  CHIPS_CREATION_FORM_SUBMIT_BUTTON_LABEL: "Create chip",
  CHIPS_CREATION_FORM_INFORMATIONS_TITLE: "Informations",
  CHIPS_CREATION_FORM_INFORMATIONS_SUBTITLE: "Main informations about the chip",
  CHIPS_CREATION_FORM_NAME_LABEL: "Name",
  CHIPS_CREATION_FORM_SUPPLIER_LABEL: "Supplier",
  CHIPS_CREATION_FORM_SUPPLIER_NO_OPTION: "No supplier found",
  CHIPS_CREATION_FORM_SUPPLIER_PLACEHOLDER: "Select a supplier",
  CHIPS_CREATION_FORM_VERSION_LABEL: "Version",
  CHIPS_CREATION_FORM_FORMAT_TITLE: "Chip format",
  CHIPS_CREATION_FORM_FORMAT_SUBTITLE: "Physical caracteristics of the chip",
  CHIPS_CREATION_FORM_FORMAT_NB_CHAMBERS_SUMMARY: "Nb chambers",
  CHIPS_CREATION_FORM_NB_COLS_LABEL: "Number of columns",
  CHIPS_CREATION_FORM_NB_ROWS_LABEL: "Number of rows",
  CHIPS_CREATION_FORM_NB_MAX_PARTITIONS_LABEL: "Partitions per chamber",
  CHIPS_CREATION_FORM_PARTITION_VOLUME_LABEL: "Partition volume",
  CHIPS_CREATION_FORM_PARTITION_VOLUME_UNIT_LABEL: "nL",
  CHIPS_CREATION_FORM_INPUT_VOLUME_LABEL: "Reaction volume",
  CHIPS_CREATION_FORM_INPUT_VOLUME_UNIT_LABEL: "μL",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_TITLE: "Quality controls",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_SUBTITLE:
    "Sufficient quantity of partitions to validate sample conformity",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_LABEL: "Required partition quantity",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_SUPERIOR_OR_EQUAL_LABEL: "≥",
  CHIPS_CREATION_CONFIRMATION_MODAL_TITLE: "Creation of a chip",
  CHIPS_CREATION_CONFIRMATION_MODAL_SUBTITLE:
    "You are about to create the new chip '{{chipName}}'. Please check the entered informations before to confirm the creation.",
  CHIPS_CREATION_CONFIRMATION_MODAL_NAME_LABEL: "Name",
  CHIPS_CREATION_CONFIRMATION_MODAL_SUPPLIER_LABEL: "Supplier",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_LABEL: "Format",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_VALUE_zero:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chamber",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_VALUE_one:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chamber",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_VALUE_other:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chambers",
  CHIPS_CREATION_CONFIRMATION_MODAL_PARTITION_VOLUME_LABEL: "Partition V.",
  CHIPS_CREATION_CONFIRMATION_MODAL_PARTITION_VOLUME_VALUE: "{{value}} nL",
  CHIPS_CREATION_CONFIRMATION_MODAL_INPUT_VOLUME_LABEL: "Reaction V.",
  CHIPS_CREATION_CONFIRMATION_MODAL_INPUT_VOLUME_VALUE: "{{value}} μL",
  CHIPS_CREATION_CONFIRMATION_MODAL_QUALITY_CONTROL_LABEL:
    "Conformity of quality controls",
  CHIPS_CREATION_CONFIRMATION_MODAL_QUALITY_CONTROL_VALUE_one:
    "from {{count}} partition",
  CHIPS_CREATION_CONFIRMATION_MODAL_QUALITY_CONTROL_VALUE_other:
    "from {{count}} partitions",
  CHIPS_CREATION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirm the creation",
  /* CHIPS DETAILS */
  CHIPS_DETAILS_CHIP_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Chip not found. Back to chips list",
  CHIPS_DETAILS_TITLE_DESCRIPTION: "Informations about the chip '{{chipName}}'",
  CHIPS_DETAILS_TITLE: "Informations about the chip '{{chipName}}'",
  CHIPS_DETAILS_INFORMATIONS_TITLE: "Informations",
  CHIPS_DETAILS_INFORMATIONS_SUBTITLE: "Main informations about the chip",
  CHIPS_DETAILS_NAME_LABEL: "Name",
  CHIPS_DETAILS_SUPPLIER_LABEL: "Supplier",
  CHIPS_DETAILS_VERSION_LABEL: "Version",
  CHIPS_DETAILS_CREATION_LABEL: "Creation",
  CHIPS_DETAILS_CREATION_DATE_VALUE: "The {{- date}}",
  CHIPS_DETAILS_CREATION_VALUE: "The {{- date}}, by {{user}}",
  CHIPS_DETAILS_LAST_MODIFICATION_LABEL: "Last modification",
  CHIPS_DETAILS_LAST_MODIFICATION_DATE_VALUE: "The {{- date}}",
  CHIPS_DETAILS_LAST_MODIFICATION_VALUE: "The {{- date}}, by {{user}}",
  CHIPS_DETAILS_FORMAT_TITLE: "Chip format",
  CHIPS_DETAILS_FORMAT_SUBTITLE: "Physical caracteristics of the chip",
  CHIPS_DETAILS_FORMAT_NB_CHAMBERS_SUMMARY: "Nb chambers",
  CHIPS_DETAILS_FORMAT_LABEL: "Format",
  CHIPS_DETAILS_FORMAT_VALUE_zero:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chamber",
  CHIPS_DETAILS_FORMAT_VALUE_one:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chamber",
  CHIPS_DETAILS_FORMAT_VALUE_other:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chambers",
  CHIPS_DETAILS_INPUT_VOLUME_LABEL: "Reaction V.",
  CHIPS_DETAILS_INPUT_VOLUME_VALUE: "{{inputVolume}} μL",
  CHIPS_DETAILS_PARTITION_VOLUME_LABEL: "Partition V.",
  CHIPS_DETAILS_PARTITION_VOLUME_VALUE: "{{partitionVolume}} nL",
  CHIPS_DETAILS_QUALITY_CONTROL_LABEL: "Conformity of quality controls",
  CHIPS_DETAILS_QUALITY_CONTROL_VALUE_zero:
    "from {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_DETAILS_QUALITY_CONTROL_VALUE_one:
    "from {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_DETAILS_QUALITY_CONTROL_VALUE_other:
    "from {{count}} partitions / {{nbMaxPartitions}}",
  CHIPS_DETAILS_QUALITY_CONTROL_TITLE: "Quality controls",
  CHIPS_DETAILS_QUALITY_CONTROL_SUBTITLE:
    "Sufficient quantity of partitions to validate sample conformity",
  CHIPS_DETAILS_EDIT_BUTTON_LABEL: "Edit the chip",
  /* CHIPS EDITION */
  CHIPS_EDITION_TITLE_DESCRIPTION: "Edit chip {{chipName}}",
  CHIPS_EDITION_SUCCESSFUL_TOAST_MESSAGE: "Chip successfully edited",
  CHIPS_EDITION_FORM_SUBMIT_BUTTON_LABEL: "Edit chip",
  CHIPS_EDITION_CONFIRMATION_MODAL_SUBTITLE:
    "You are about to modify the chip '{{chipName}}'. Please check the entered informations before to confirm the modification.",
  CHIPS_EDITION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirm modification",
  /* CHIPS LITS */
  BACK_TO_CHIPS_LIST: "Back to chips list",
  CHIPS_LIST_TITLE_DESCRIPTION: "Chips list",
  CHIPS_LIST_TITLE: "Chips list",
  CHIPS_LIST_ADD_NEW_CHIP_BUTTON: "Add new chip",
  CHIPS_LIST_SEARCH_CHIP_LABEL: "Search chip",
  CHIPS_LIST_COUNT_LABEL_zero: "{{count}} chip",
  CHIPS_LIST_COUNT_LABEL_one: "{{count}} chip",
  CHIPS_LIST_COUNT_LABEL_other: "{{count}} chips",
  CHIPS_LIST_HEADER_ALL_CHIPS: "All chips",
  CHIPS_LIST_HEADER_ACTIVE_CHIPS: "Active chips",
  CHIPS_LIST_FILTER_ALL_CHIPS: "All",
  CHIPS_LIST_FILTER_ACTIVE_CHIPS: "Active",
  CHIPS_LIST_FILTER_TABS_LABEL: "Filter chips by activity",
  CHIPS_LIST_CARD_NAME_LABEL: "Name",
  CHIPS_LIST_CARD_SUPPLIER_LABEL: "Supplier",
  CHIPS_LIST_CARD_FORMAT_LABEL: "Format",
  CHIPS_LIST_CARD_FORMAT_VALUE_zero:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chamber",
  CHIPS_LIST_CARD_FORMAT_VALUE_one:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chamber",
  CHIPS_LIST_CARD_FORMAT_VALUE_other:
    "{{nbRows}} R / {{nbCols}} C, {{count}} chambers",
  CHIPS_LIST_CARD_PARTITIONS_NUMBER_LABEL: "Partitions per chamber",
  CHIPS_LIST_CARD_INPUT_VOLUME_LABEL: "Reaction V.",
  CHIPS_LIST_CARD_INPUT_VOLUME_VALUE: "{{inputVolume}} μL",
  CHIPS_LIST_CARD_PARTITION_VOLUME_LABEL: "Partition V.",
  CHIPS_LIST_CARD_PARTITION_VOLUME_VALUE: "{{partitionVolume}} nL",
  CHIPS_LIST_CARD_IS_ACTIVE: "Active",
  CHIPS_LIST_CARD_IS_INACTIVE: "Archived",
  CHIPS_LIST_CARD_MENU_BUTTON_LABEL: "Actions for the chip",
  CHIPS_LIST_CARD_MENU_NEW_VERSION: "New version",
  CHIPS_LIST_CARD_MENU_ACTIVATE: "Activate",
  CHIPS_LIST_CARD_MENU_DEACTIVATE: "Archive",
  CHIPS_LIST_CARD_MENU_DELETE: "Delete",
  CHIPS_LIST_CARD_MENU_EDIT: "Edit",
  CHIPS_LIST_CARD_VERSION_LABEL: "Version",
  CHIPS_LIST_CARD_QUALITY_CONTROL_LABEL: "Minimum number of partitions",
  CHIPS_LIST_CARD_QUALITY_CONTROL_VALUE_zero:
    "> {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_LIST_CARD_QUALITY_CONTROL_VALUE_one:
    "> {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_LIST_CARD_QUALITY_CONTROL_VALUE_other:
    "> {{count}} partitions / {{nbMaxPartitions}}",
  CHIPS_LIST_CARD_SEE_DETAILS_LINK: "See in details",
  CHIPS_LIST_CARD_DELETION_MODAL_TITLE: "Removal of the chip\n'{{chipName}}'",
  CHIPS_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Are you sure you want to remove the chip '{{chipName}}' ?",
  CHIPS_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Remove the chip",
  CHIPS_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Chip deleted successfully",
  CHIPS_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the removal of the chip. Please retry.",
  CHIPS_LIST_ACTIVATE_SUCCESS_TOAST_MESSAGE: "Chip activated successfully",
  CHIPS_LIST_ACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the activation of the chip. Please retry.",
  CHIPS_LIST_DEACTIVATE_SUCCESS_TOAST_MESSAGE: "Chip archived successfully",
  CHIPS_LIST_DEACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the archiving of the chip. Please retry.",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_TITLE:
    "Creation of new version of the chip\n'{{chipName}}'",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_SUBTITLE:
    "You are about to create a new version of the chip '{{chipName}}'. Enter the new version number and confirm.",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_FORM_NEW_VERSION_LABEL: "New version",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_FORM_SUBMIT_BUTTON: "Create new version",
  CHIPS_LIST_CREATE_NEW_VERSION_SUCCESS_TOAST_MESSAGE:
    "New chip version created successfully",
  CHIPS_LIST_CREATE_NEW_VERSION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the creation of a new version of the chip. Please retry.",
  /* COMMENT MODAL */
  COMMENT_MODAL_TITLE: "Comments",
  COMMENT_MODAL_COMMENTS: "Comments",
  COMMENT_MODAL_ADD_LABEL: "Add comment",
  COMMENT_MODAL_FOLLOW_LINK: "Visit",
  COMMENT_MODAL_ADD_DESCRIPTION:
    "There is no comment yet. Would you like to add one?",
  COMMENT_MODAL_ADD_COMMENT_TEXTAREA_PLACEHOLDER: "Your comment",
  COMMENT_MODAL_ADD_COMMENT_SUBMIT: "Add comment",
  /* CONTACT */
  CONTACT_TITLE_DESCRIPTION: "Contact Geeng team",
  CONTACT_TITLE: "Contact Geeng team",
  CONTACT_SUBTITLE: "Fill the contact form. We will come back to you quickly.",
  CONTACT_FORM_USER_INFORMATIONS_TITLE: "Informations",
  CONTACT_FORM_USER_INFORMATIONS_SUBTITLE: "User informations",
  CONTACT_FORM_NAME_LABEL: "Name",
  CONTACT_FORM_MAIL_LABEL: "Mail address",
  CONTACT_FORM_MESSAGE_TITLE: "Your message",
  CONTACT_FORM_SUBMIT_BUTTON_LABEL: "Send",
  CONTACT_FORM_SUCCESSFUL_TOAST_MESSAGE:
    "Message successfully sent (no backend action for now)",
  /* DPCR HOME */
  DPCR_HOME_TITLE_DESCRIPTION: "DPCR Application",
  DPCR_HOME_TITLE: "DPCR Application",
  DPCR_HOME_TABS_LABEL:
    "Browse statistics on current series and runs, matrices, analyses and targets",
  DPCR_HOME_TAB_IN_PROGRESS: "In progress",
  DPCR_HOME_TAB_MATRICES: "Matrices",
  DPCR_HOME_TAB_ANALYSES: "Analyses",
  DPCR_HOME_TAB_TARGETS: "Targets",
  DPCR_HOME_NO_DATA: "An error occurred during data recovery",
  /* DPCR HOME IN_PROGRESS */
  DPCR_HOME_IN_PROGRESS_TITLE: "Series and runs in progress",
  DPCR_HOME_IN_PROGRESS_DESCRIPTION:
    "List of series and runs to do or in progress. Each run corresponds to a specific analysis and may contain several samples.",
  /* DPCR HOME MATRICES */
  DPCR_HOME_MATRICES_TITLE: "Data per matrix",
  DPCR_HOME_MATRICES_DESCRIPTION:
    "Find positive, negative, non-contributive interpretations for each used matrix",
  DPCR_HOME_MATRICES_TABS_LABEL: "View data for each matrix",
  DPCR_HOME_MATRICES_TAB_ALL_MATRICES: "All",

  DPCR_HOME_MATRICES_PIE_CHART_POSITIVE_LABEL: "Positive Interp.",
  DPCR_HOME_MATRICES_PIE_CHART_NEGATIVE_LABEL: "Negative Interp.",
  DPCR_HOME_MATRICES_PIE_CHART_NON_CONTRIBUTIVE_LABEL:
    "Non-contributive Interp.",
  DPCR_HOME_MATRICES_PIE_CHART_TOOLTIP_SAMPLES_zero: "Sample",
  DPCR_HOME_MATRICES_PIE_CHART_TOOLTIP_SAMPLES_one: "Sample",
  DPCR_HOME_MATRICES_PIE_CHART_TOOLTIP_SAMPLES_other: "Samples",
  /* DPCR HOME ANALYSES */
  DPCR_HOME_ANALYSES_TITLE: "Data per analysis",
  DPCR_HOME_ANALYSES_DESCRIPTION:
    "Find the positive, negative or non-contributory interpretations for each analysis and matrix used.",
  DPCR_HOME_ANALYSES_GRAPH_LABEL:
    "Bar chart showing number of interpretations per analysis and matrix",
  DPCR_HOME_ANALYSES_GRAPH_BAR_LABEL_zero:
    "{{count}} interpretation {{id}} for the analysis {{analysis}} and the matrix {{matrix}}",
  DPCR_HOME_ANALYSES_GRAPH_BAR_LABEL_one:
    "{{count}} interpretation {{id}} for the analysis {{analysis}} and the matrix {{matrix}}",
  DPCR_HOME_ANALYSES_GRAPH_BAR_LABEL_other:
    "{{count}} interpretations {{id}} for the analysis {{analysis}} and the matrix {{matrix}}",
  DPCR_HOME_ANALYSES_BAR_CHART_NON_CONTRIBUTIVE: "Non-Contr.",
  DPCR_HOME_ANALYSES_BAR_CHART_NEGATIVE_zero: "Negative",
  DPCR_HOME_ANALYSES_BAR_CHART_NEGATIVE_one: "Negative",
  DPCR_HOME_ANALYSES_BAR_CHART_NEGATIVE_other: "Negatives",
  DPCR_HOME_ANALYSES_BAR_CHART_POSITIVE_zero: "Positive",
  DPCR_HOME_ANALYSES_BAR_CHART_POSITIVE_one: "Positive",
  DPCR_HOME_ANALYSES_BAR_CHART_POSITIVE_other: "Positives",
  /* DPCR HOME TARGETS */
  DPCR_HOME_TARGETS_TITLE: "Data per target",
  DPCR_HOME_TARGETS_DESCRIPTION:
    "Find the number of positive interpretations for each of your analysis targets. The data is once again sorted by matrix. By selecting an analysis, you can zoom in on its targets.",
  DPCR_HOME_TARGETS_GRAPH_LABEL:
    "Bar chart showing the number of positive interpretations for each analysis target for the selected matrix",
  DPCR_HOME_TARGETS_GRAPH_BAR_LABEL_zero:
    "{{count}} positive interpretation for target {{id}} of analysis {{analysis}} and matrix {{matrix}}",
  DPCR_HOME_TARGETS_GRAPH_BAR_LABEL_one:
    "{{count}} positive interpretation for target {{id}} of analysis {{analysis}} and matrix {{matrix}}",
  DPCR_HOME_TARGETS_GRAPH_BAR_LABEL_other:
    "{{count}} positive interpretations for target {{id}} of analysis {{analysis}} and matrix {{matrix}}",
  DPCR_HOME_TARGETS_BAR_CHART_TOOLTIP_ANALYSIS: "Analysis",
  DPCR_HOME_TARGETS_BAR_CHART_TOOLTIP_TARGET: "Target",
  DPCR_HOME_TARGETS_BAR_CHART_TOOLTIP_POSITIVE: "Positive",
  DPCR_HOME_TARGETS_BAR_CHART_TOOLTIP_POSITIVE_PERCENTAGE: "% Positive",
  DPCR_HOME_TARGETS_BACK_TO_ANALYSES_BUTTON: "All analyses",
  DPCR_HOME_TARGETS_SELECTED_ANALYSIS_ANALYSIS_LABEL: "Analysis",
  DPCR_HOME_TARGETS_SELECTED_ANALYSIS_SAMPLES_RATIO_LABEL_zero:
    "Positive sample",
  DPCR_HOME_TARGETS_SELECTED_ANALYSIS_SAMPLES_RATIO_LABEL_one:
    "Positive sample",
  DPCR_HOME_TARGETS_SELECTED_ANALYSIS_SAMPLES_RATIO_LABEL_other:
    "Positive samples",
  DPCR_HOME_TARGETS_SELECTED_ANALYSIS_PERCENTAGE_LABEL: "% positive samples",
  /* DPCR HOME LEFT PANEL */
  DPCR_HOME_LEFT_PANEL_NO_DATA: "No data",
  DPCR_HOME_LEFT_PANEL_PATIENTS: "Patients",
  DPCR_HOME_LEFT_PANEL_PATIENTS_COUNT_LAST_MONTH: "{{value}} this month",
  DPCR_HOME_LEFT_PANEL_SAMPLES: "Samples",
  DPCR_HOME_LEFT_PANEL_IN_PROGRESS: "In progress",
  DPCR_HOME_LEFT_PANEL_IN_PROGRESS_SERIES_TO_VALIDATE: "Series to validate",
  DPCR_HOME_LEFT_PANEL_IN_PROGRESS_SERIES_WAITING_FOR_RESULTS:
    "Series waiting for results",
  DPCR_HOME_LEFT_PANEL_GLOBAL: "Global view",
  DPCR_HOME_LEFT_PANEL_GLOBAL_DESCRIPTION:
    "A summary of recent activities, including the total number of dPCRs, runs, samples and analyses performed.",
  DPCR_HOME_LEFT_PANEL_GLOBAL_DPCR: "dPCR",
  DPCR_HOME_LEFT_PANEL_GLOBAL_RUNS: "Runs",
  DPCR_HOME_LEFT_PANEL_GLOBAL_SAMPLES: "Samples",
  DPCR_HOME_LEFT_PANEL_GLOBAL_ANALYSES: "Analyses",
  DPCR_HOME_LEFT_PANEL_GLOBAL_INDICATIONS: "Indications",
  DPCR_HOME_LEFT_PANEL_GLOBAL_MATRICES: "Matrices",
  DPCR_HOME_LEFT_PANEL_GLOBAL_ORIGINES: "Origines",
  DPCR_HOME_LEFT_PANEL_GLOBAL_AVERAGE_TIME_SINCE_PRECRIPTION:
    "Average time since prescription",
  DPCR_HOME_LEFT_PANEL_GLOBAL_AVERAGE_TIME_SINCE_RECEPTION:
    "Average time since reception",
  /* DOCR HOME DASHBOARDS-0 */
  DPCR_HOME_YOUR_SERIES: "Your dPCR series and runs",
  DPCR_HOME_YOUR_MATERIALS: "Your dPCR analysis tools",
  DPCR_HOME_SERIES_COUNT_zero: "{{count}} series",
  DPCR_HOME_SERIES_COUNT_one: "{{count}} series",
  DPCR_HOME_SERIES_COUNT_other: "{{count}} series",
  DPCR_HOME_SERIES_SUBTITLE: "Interpret your sample analysis results",
  DPCR_HOME_RUNS_COUNT_zero: "{{count}} run",
  DPCR_HOME_RUNS_COUNT_one: "{{count}} run",
  DPCR_HOME_RUNS_COUNT_other: "{{count}} runs",
  DPCR_HOME_RUNS_SUBTITLE: "Track the validation of your samples",
  DPCR_HOME_ANALYSES_COUNT_zero: "{{count}} analysis",
  DPCR_HOME_ANALYSES_COUNT_one: "{{count}} analysis",
  DPCR_HOME_ANALYSES_COUNT_other: "{{count}} analyses",
  DPCR_HOME_ANALYSES_SUBTITLE: "Add, remove, update one of your lab analyses",
  DPCR_HOME_KITS_COUNT_zero: "{{count}} kit",
  DPCR_HOME_KITS_COUNT_one: "{{count}} kit",
  DPCR_HOME_KITS_COUNT_other: "{{count}} kits",
  DPCR_HOME_KITS_SUBTITLE: "Add, remove, update one of your lab kits",
  DPCR_HOME_CHIPS_COUNT_zero: "{{count}} chip",
  DPCR_HOME_CHIPS_COUNT_one: "{{count}} chip",
  DPCR_HOME_CHIPS_COUNT_other: "{{count}} chips",
  DPCR_HOME_CHIPS_SUBTITLE: "Add, remove, update one of your lab chips",
  /* DPCR_SIDEBAR */
  DPCR_SIDEBAR_SEARCH_INPUT_HELPERTEXT: "Search",
  DPCR_SIDEBAR_SEARCH_INPUT_LABEL: "Search by sample number or mutation",
  DPCR_SIDEBAR_SEARCH_INPUT_NO_OPTION: "No result found",
  DPCR_SIDEBAR_ITEMS_DASHBOARD: "Dashboard",
  DPCR_SIDEBAR_ITEMS_SERIES_RUNS: "Series / Runs",
  DPCR_SIDEBAR_ITEMS_RUNS_LIST: "Runs list",
  DPCR_SIDEBAR_ITEMS_SERIES_LIST: "Series list",
  DPCR_SIDEBAR_ITEMS_SERIES_RUNS_CREATE: "Create a new Series",
  DPCR_SIDEBAR_ITEMS_SERIES_RUNS_UPLOAD_RESULTS: "Upload results",
  DPCR_SIDEBAR_ITEMS_ANALYSES: "Analyses",
  DPCR_SIDEBAR_ITEMS_ANALYSES_LIST: "Analyses list",
  DPCR_SIDEBAR_ITEMS_ANALYSES_BATCH_MANAGEMENT: "Batch management",
  DPCR_SIDEBAR_ITEMS_NOTIFICATIONS: "Notifications",
  DPCR_SIDEBAR_ITEMS_SETTINGS: "Set-up",
  DPCR_SIDEBAR_ITEMS_SETTINGS_ANALYSIS_CREATE: "Create new analysis",
  DPCR_SIDEBAR_ITEMS_SETTINGS_KITS_SEE: "Kits list",
  DPCR_SIDEBAR_ITEMS_SETTINGS_KITS_CREATE: "Create new kit",
  DPCR_SIDEBAR_ITEMS_SETTINGS_CHIPS_SEE: "Chips list",
  DPCR_SIDEBAR_ITEMS_SETTINGS_CHIPS_CREATE: "Create new chip",
  DPCR_SIDEBAR_ITEMS_SETTINGS_SOFTWARES_SEE: "Softwares list",
  DPCR_SIDEBAR_ITEMS_ADMINISTRATION: "Administration",
  DPCR_SIDEBAR_ITEMS_LOGOUT: "Log out",
  /* ENTITIES */
  ANALYSIS: "Analysis",
  /* ERRORS */
  ERROR_FILE_VALIDATION_ERROR_MISSING_COLUMNS:
    "File validation error : Samplesheet is missing required columns: SAMPLE ID, CHAMBER ID, ANALYSIS, SPEC TYPE",
  ERROR_NOT_ALLOWED_KIT_BATCH_ANALYSIS_MISMATCH:
    "Kit batch and analysis must be linked to the same kit",
  ERROR_INTERNAL_SERVER_ERROR: "An error has occured",
  ERROR_MISSING_FIELD_RUNID: "The field runId is not defined in the form.",
  ERROR_NOT_FOUND: "Entity not found.",
  ERROR_NOT_FOUND_VARIABLE_MALE: "{{entity}} not found.",
  ERROR_NOT_FOUND_VARIABLE_FEMALE: "{{entity}} not found.",
  ERROR_NOT_ALLOWED_RUN_STATUS_NOT_VALID:
    "Impossible to modify the result file if the run status is 'Ready'",
  ERROR_PAYLOAD_VALIDATION_ERROR_LIST_TYPE:
    "The field does not have the expected list format",
  ERROR_PAYLOAD_VALIDATION_ERROR_VALUE_ERROR:
    "The field does not have the expected format",
  ERROR_RULE_VALIDATION_ERROR_VARIABLE_NOT_FOUND:
    "The variable used in the formula does not exist for the analysis",
  ERROR_UNIQUE_CONSTRAINT_VIOLATION:
    "A record with the same unique values already exists.",
  /* FORM */
  FORM_REQUIRED_FIELD: "Required field",
  FORM_REQUIRED_AT_LEAST_ONE_ELEMENT:
    "This field must contain at least 1 element",
  FORM_NO_OPTION_FOUND: "No option found",
  FORM_UNIQUE_VALUES: "All values must be different",
  /* INTERPRETATIONS */
  MUTATED_zero: "Mutated",
  MUTATED_one: "Mutated",
  MUTATED_other: "Mutated",
  UNMUTATED_zero: "Unmutated",
  UNMUTATED_one: "Unmutated",
  UNMUTATED_other: "Unmutated",
  /* KITS CREATION */
  KITS_CREATION_TITLE_DESCRIPTION: "Kit creation",
  KITS_CREATION_TITLE: "Kit creation",
  KITS_CREATION_SUBTITLE:
    "Define here the kit informations : a name, a supplier, a version number, the sample matrices on which it can be used and the list of targets.",
  KITS_CREATION_FORM_NAME_LABEL: "Name",
  KITS_CREATION_FORM_SUPPLIER_LABEL: "Supplier",
  KITS_CREATION_FORM_SUPPLIER_NO_OPTION: "No supplier found",
  KITS_CREATION_FORM_SUPPLIER_PLACEHOLDER: "Select a supplier",
  KITS_CREATION_FORM_VERSION_LABEL: "Version",
  KITS_CREATION_FORM_MATRICES_LABEL: "Matrices",
  KITS_CREATION_FORM_MATRICES_PLACEHOLDER: "Select matrices",
  KITS_CREATION_FORM_INFORMATIONS_TITLE: "Informations",
  KITS_CREATION_FORM_INFORMATIONS_SUBTITLE: "Main informations about the kit",
  KITS_CREATION_FORM_TARGETS_LABEL: "Target name",
  KITS_CREATION_FORM_TARGETS_TITLE: "Targets",
  KITS_CREATION_FORM_TARGETS_SUBTITLE: "List of targets covered by the kit",
  KITS_CREATION_FORM_TARGETS_NO_OPTION: "No target found",
  KITS_CREATION_FORM_TARGETS_PLACEHOLDER: "Fill its name",
  KITS_CREATION_FORM_TARGETS_ADD_BUTTON: "Add new target",
  KITS_CREATION_FORM_TARGETS_TARGET_INDEX: "Target n°{{index}}",
  KITS_CREATION_FORM_TARGETS_SUBMIT: "Create the kit",
  KITS_CREATION_FORM_TARGETS_SUMMARY_zero: "{{count}} Target",
  KITS_CREATION_FORM_TARGETS_SUMMARY_one: "{{count}} Target",
  KITS_CREATION_FORM_TARGETS_SUMMARY_other: "{{count}} Targets",
  KITS_CREATION_SUCCESS_TOAST_MESSAGE: "Kit created successfully",
  KITS_CREATION_CONFIRMATION_MODAL_TITLE_zero:
    "Creation of a kit with {{count}} Target",
  KITS_CREATION_CONFIRMATION_MODAL_TITLE_one:
    "Creation of a kit with {{count}} Target",
  KITS_CREATION_CONFIRMATION_MODAL_TITLE_other:
    "Creation of a kit with {{count}} Targets",
  KITS_CREATION_CONFIRMATION_MODAL_SUBTITLE_zero:
    "You are about to create the kit '{{kitName}}' with a panel of {{count}} Target. Please check the entered informations before to confirm the creation.",
  KITS_CREATION_CONFIRMATION_MODAL_SUBTITLE_one:
    "You are about to create the kit '{{kitName}}' with a panel of {{count}} Target. Please check the entered informations before to confirm the creation.",
  KITS_CREATION_CONFIRMATION_MODAL_SUBTITLE_other:
    "You are about to create the kit '{{kitName}}' with a panel of {{count}} Targets. Please check the entered informations before to confirm the creation.",
  KITS_CREATION_CONFIRMATION_MODAL_NAME_LABEL: "Name",
  KITS_CREATION_CONFIRMATION_MODAL_TARGETS_LABEL: "Targets Nb",
  KITS_CREATION_CONFIRMATION_MODAL_SUPPLIER_LABEL: "Supplier",
  KITS_CREATION_CONFIRMATION_MODAL_VERSION_LABEL: "Version",
  KITS_CREATION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirm the creation",
  /* KITS DETAILS */
  KITS_DETAILS_TITLE_DESCRIPTION: "Informations about the kit '{{kitName}}'",
  KITS_DETAILS_TITLE: "Informations about the kit '{{kitName}}'",
  KITS_DETAILS_INFORMATIONS_TITLE: "Informations",
  KITS_DETAILS_INFORMATIONS_SUBTITLE: "Main informations about the kit",
  KITS_DETAILS_NAME_LABEL: "Name",
  KITS_DETAILS_SUPPLIER_LABEL: "Supplier",
  KITS_DETAILS_VERSION_LABEL: "Version",
  KITS_DETAILS_MATRICES_LABEL: "Matrices",
  KITS_DETAILS_CREATION_LABEL: "Creation",
  KITS_DETAILS_CREATION_DATE_VALUE: "The {{- date}}",
  KITS_DETAILS_CREATION_VALUE: "The {{- date}}, by {{user}}",
  KITS_DETAILS_LAST_MODIFICATION_LABEL: "Last modification",
  KITS_DETAILS_LAST_MODIFICATION_DATE_VALUE: "The {{- date}}",
  KITS_DETAILS_LAST_MODIFICATION_VALUE: "The {{- date}}, by {{user}}",
  KITS_DETAILS_TARGETS_TITLE: "All kit targets",
  KITS_DETAILS_TARGETS_COUNT_LABEL_zero: "{{count}} Target",
  KITS_DETAILS_TARGETS_COUNT_LABEL_one: "{{count}} Target",
  KITS_DETAILS_TARGETS_COUNT_LABEL_other: "{{count}} Targets",
  KITS_DETAILS_KIT_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Kit not found. Back to kits list",
  KITS_DETAILS_EDIT_BUTTON_LABEL: "Edit kit",
  /* KITS EDITION */
  KITS_EDITION_SUCCESSFUL_TOAST_MESSAGE: "Kit successfully updated",
  KITS_EDITION_TITLE_DESCRIPTION: "Kit edition {{kitName}}",
  KITS_EDITION_FORM_SUBMIT_BUTTON_LABEL: "Edit kit",
  KITS_EDITION_CONFIRMATION_MODAL_SUBTITLE_zero:
    "You are about to modify the {{kitName}} kit with a panel of {{count}} target. Please check the information entered before confirming the modification.",
  KITS_EDITION_CONFIRMATION_MODAL_SUBTITLE_one:
    "You are about to modify the {{kitName}} kit with a panel of {{count}} target. Please check the information entered before confirming the modification.",
  KITS_EDITION_CONFIRMATION_MODAL_SUBTITLE_other:
    "You are about to modify the {{kitName}} kit with a panel of {{count}} targets. Please check the information entered before confirming the modification.",
  KITS_EDITION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirm edition",
  /* KITS LITS */
  BACK_TO_KITS_LIST: "Back to kits list",
  KITS_LIST_TITLE_DESCRIPTION: "Kits list",
  KITS_LIST_TITLE: "Kits list",
  KITS_LIST_ADD_NEW_KIT_BUTTON: "Add new kit",
  KITS_LIST_FILTER_TABS_LABEL: "Filter kits by activity",
  KITS_LIST_HEADER_ALL_KITS: "All kits",
  KITS_LIST_HEADER_ACTIVE_KITS: "Active kits",
  KITS_LIST_FILTER_ALL_KITS: "All",
  KITS_LIST_FILTER_ACTIVE_KITS: "Active",
  KITS_LIST_SEARCH_KIT_LABEL: "Search kit",
  KITS_LIST_COUNT_LABEL_zero: "{{count}} kit",
  KITS_LIST_COUNT_LABEL_one: "{{count}} kit",
  KITS_LIST_COUNT_LABEL_other: "{{count}} kits",
  KITS_LIST_CARD_NAME_LABEL: "Name",
  KITS_LIST_CARD_SUPPLIER_LABEL: "Supplier",
  KITS_LIST_CARD_TARGETS_LABEL: "Targets Nb",
  KITS_LIST_CARD_MATRICES_LABEL: "Matrices",
  KITS_LIST_CARD_VERSION_LABEL: "Version",
  KITS_LIST_CARD_IS_ACTIVE: "Active",
  KITS_LIST_CARD_IS_INACTIVE: "Archived",
  KITS_LIST_CARD_MENU_BUTTON_LABEL: "Actions for the kit",
  KITS_LIST_CARD_MENU_NEW_VERSION: "New version",
  KITS_LIST_CARD_MENU_DEACTIVATE: "Archive",
  KITS_LIST_CARD_MENU_ACTIVATE: "Activate",
  KITS_LIST_CARD_MENU_DELETE: "Delete",
  KITS_LIST_CARD_MENU_EDIT: "Edit",
  KITS_LIST_CARD_MENU_CREATE_FROM: "Create from",
  KITS_LIST_CARD_SEE_DETAILS_LINK: "See in details",
  KITS_LIST_CARD_DELETION_MODAL_TITLE: "Removal of the kit\n'{{kitName}}'",
  KITS_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Are you sure you want to remove the kit '{{kitName}}' ?",
  KITS_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Remove the kit",
  KITS_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Kit removal successful",
  KITS_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the removal of the kit. Please retry.",
  KITS_LIST_ACTIVATE_SUCCESS_TOAST_MESSAGE: "Kit activated successfully",
  KITS_LIST_ACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the activation of the kit. Please retry.",
  KITS_LIST_DEACTIVATE_SUCCESS_TOAST_MESSAGE: "Kit archived successfully",
  KITS_LIST_DEACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the archiving of the kit. Please retry.",
  KITS_LIST_CARD_NEW_VERSION_MODAL_TITLE:
    "Creation of a new version of the kit\n'{{kitName}}'",
  KITS_LIST_CARD_NEW_VERSION_MODAL_SUBTITLE:
    "You are about to create a new version of the kit '{{kitName}}'. Enter the new version number and confirm.",
  KITS_LIST_CARD_NEW_VERSION_MODAL_FORM_NEW_VERSION_LABEL: "New version",
  KITS_LIST_CARD_NEW_VERSION_MODAL_FORM_SUBMIT_BUTTON: "Create new version",
  KITS_LIST_CREATE_NEW_VERSION_SUCCESS_TOAST_MESSAGE:
    "New kit version created successfully",
  KITS_LIST_CREATE_NEW_VERSION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the creation of a new version of the kit. Please retry.",
  /* KIT BATCHES CREATION */
  KIT_BATCHES_CREATION_TITLE_DESCRIPTION: "Creation of a new kit batch",
  KIT_BATCHES_CREATION_TITLE: "Creation of a new kit batch",
  KIT_BATCHES_CREATION_FORM_INFORMATIONS_TITLE: "Informations",
  KIT_BATCHES_CREATION_FORM_INFORMATIONS_SUBTITLE:
    "Main informations of the kit batch",
  KIT_BATCHES_CREATION_FORM_BATCH_NUMBER_LABEL: "Kit batch number",
  KIT_BATCHES_CREATION_FORM_ANALYSIS_LABEL: "Related analysis",
  KIT_BATCHES_CREATION_FORM_ANALYSIS_PLACEHOLDER: "Select analysis",
  KIT_BATCHES_CREATION_FORM_ANALYSIS_NO_OPTION: "No analysis found",
  KIT_BATCHES_CREATION_FORM_EXPIRY_LABEL: "Expiry date",
  KIT_BATCHES_CREATION_FORM_IS_ACTIVE_LABEL: "Activity status",
  KIT_BATCHES_CREATION_FORM_IS_ACTIVE_DESCRIPTION:
    "By default the batch is active, you can choose to make it inactive now or will be able to change this status after creation",
  KIT_BATCHES_CREATION_FORM_TARGETS_TITLE: "Targets related to the kit batch",
  KIT_BATCHES_CREATION_FORM_TARGETS_DESCRIPTION:
    "Define for each target the minimum and maximum acceptance boundary for the validation of controls for this batch",
  KIT_BATCHES_CREATION_FORM_TARGETS_NO_SELECTION:
    "First select an analysis to display targets related to the kit batch",
  KIT_BATCHES_CREATION_FORM_THRESHOLDS_LOWER_THRESHOLD: "Min. bound",
  KIT_BATCHES_CREATION_FORM_THRESHOLDS_UNDEFINED_PLACEHOLDER: "Not defined",
  KIT_BATCHES_CREATION_FORM_THRESHOLDS_LOWER_THRESHOLD_BADGE_LABEL:
    "Min. {{validated}}/{{expected}}",
  KIT_BATCHES_CREATION_FORM_THRESHOLDS_UPPER_THRESHOLD: "Max. bound",
  KIT_BATCHES_CREATION_FORM_THRESHOLDS_UPPER_THRESHOLD_BADGE_LABEL:
    "Max. {{validated}}/{{expected}}",
  KIT_BATCHES_CREATION_FORM_SUBMIT: "Create the kit batch",
  /* KIT BATCHES EDITION CONFIRMATION MODAL */
  KIT_BATCHES_CONFIRMATION_MODAL_WARNING_TITLE_zero:
    "Warning: {{count}}/{{expected}} bound indicated",
  KIT_BATCHES_CONFIRMATION_MODAL_WARNING_TITLE_one:
    "Warning: {{count}}/{{expected}} bound indicated",
  KIT_BATCHES_CONFIRMATION_MODAL_WARNING_TITLE_other:
    "Warning: {{count}}/{{expected}} bounds indicated",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_WARNING_DESCRIPTION_zero:
    "You are about to edit the '{{kitBatchName}}' batch. However, you have only filled in {{count}} of the {{expected}} bounds. The definition of these values has an impact on the validation of controls in analyses that will use this batch.",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_WARNING_DESCRIPTION_one:
    "You are about to edit the '{{kitBatchName}}' batch. However, you have only filled in {{count}} of the {{expected}} bounds. The definition of these values has an impact on the validation of controls in analyses that will use this batch.",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_WARNING_DESCRIPTION_other:
    "You are about to edit the '{{kitBatchName}}' batch. However, you have only filled in {{count}} of the {{expected}} bounds. The definition of these values has an impact on the validation of controls in analyses that will use this batch.",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_WARNING_QUESTION:
    "Are you sure you want to go ahead with the edition?",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_SUBTITLE_zero:
    "You are about to edit the kit batch '{{kitBatchName}}' related to a panel of {{count}} target. Please check the entered informations before to confirm the creation.",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_SUBTITLE_one:
    "You are about to edit the kit batch '{{kitBatchName}}' related to a panel of {{count}} target. Please check the entered informations before to confirm the creation.",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_SUBTITLE_other:
    "You are about to edit the kit batch '{{kitBatchName}}' related to a panel of {{count}} targets. Please check the entered informations before to confirm the creation.",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_NAME_LABEL: "Batch number",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_ANALYSIS_LABEL: "Related analysis",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_TARGETS_LABEL:
    "Number of related targets",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_EXPIRY_LABEL: "Expiry date",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_IS_ACTIVE_LABEL: "Activity status",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirm the creation",
  KIT_BATCHES_EDITION_SUCCESSFUL_TOAST_MESSAGE:
    "Kit batch successfully updated",
  /* KIT BATCHES CREATION */
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_TITLE:
    "Creation of kit batch '{{kitBatchName}}'",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_WARNING_DESCRIPTION_zero:
    "You are about to create the '{{kitBatchName}}' batch. However, you have only filled in {{count}} of the {{expected}} bounds}. The definition of these values has an impact on the validation of controls in analyses that will use this batch.",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_WARNING_DESCRIPTION_one:
    "You are about to create the '{{kitBatchName}}' batch. However, you have only filled in {{count}} of the {{expected}} bounds. The definition of these values has an impact on the validation of controls in analyses that will use this batch.",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_WARNING_DESCRIPTION_other:
    "You are about to create the '{{kitBatchName}}' batch. However, you have only filled in {{count}} of the {{expected}} bounds. The definition of these values has an impact on the validation of controls in analyses that will use this batch.",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_WARNING_QUESTION:
    "Are you sure you want to go ahead with the creation?",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_SUBTITLE_zero:
    "You are about to edit the new kit batch '{{kitBatchName}}' related to a panel of {{count}} target. Please check the entered informations before to confirm the edition.",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_SUBTITLE_one:
    "You are about to edit the new kit batch '{{kitBatchName}}' related to a panel of {{count}} target. Please check the entered informations before to confirm the edition.",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_SUBTITLE_other:
    "You are about to edit the new kit batch '{{kitBatchName}}' related to a panel of {{count}} targets. Please check the entered informations before to confirm the edition.",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirm the edition",
  KIT_BATCHES_CREATION_SUCCESS_TOAST_MESSAGE: "Kit batch successfully created",
  /* KIT BATCHES LIST */
  BACK_TO_KIT_BATCHES_LIST: "Back to kit batches list",
  KIT_BATCHES_LIST_TITLE_DESCRIPTION: "Kit batches list",
  KIT_BATCHES_LIST_TITLE: "Kit batches list",
  KIT_BATCHES_LIST_ADD_NEW_KIT_BATCH: "Add kit batch",
  KIT_BATCHES_LIST_FILTER_TABS_LABEL: "Filter kit batches by activity",
  KIT_BATCHES_LIST_FILTER_ALL_BATCHES: "All",
  KIT_BATCHES_LIST_FILTER_ACTIVE_BATCHES: "Active",
  KIT_BATCHES_LIST_TABLE_HEADER_BADGE_zero: "{{count}} kit batch",
  KIT_BATCHES_LIST_TABLE_HEADER_BADGE_one: "{{count}} kit batch",
  KIT_BATCHES_LIST_TABLE_HEADER_BADGE_other: "{{count}} kit batches",
  KIT_BATCHES_LIST_TABLE_HEADER_FILTER_ANALYSIS_LABEL: "Analysis",
  KIT_BATCHES_LIST_TABLE_HEADER_FILTER_RUN_NO_OPTION_MESSAGE:
    "No analysis found",
  KIT_BATCHES_LIST_TABLE_HEADER_FILTER_DATE_LABEL: "Creation date",
  KIT_BATCHES_LIST_TABLE_FILTER_RESET_LABEL: "Reset filters",
  KIT_BATCHES_LIST_TABLE_COLUMN_BATCH_NUMBER: "Batch number",
  KIT_BATCHES_LIST_TABLE_COLUMN_ANALYSIS: "Analysis",
  KIT_BATCHES_LIST_TABLE_COLUMN_TARGETS: "Targets number",
  KIT_BATCHES_LIST_TABLE_TARGETS_NUMBER_zero: "{{count}} target",
  KIT_BATCHES_LIST_TABLE_TARGETS_NUMBER_one: "{{count}} target",
  KIT_BATCHES_LIST_TABLE_TARGETS_NUMBER_other: "{{count}} targets",
  KIT_BATCHES_LIST_TABLE_COLUMN_CREATION_DATE: "Created at",
  KIT_BATCHES_LIST_TABLE_COLUMN_EXPIRATION_DATE: "Expiration date",
  KIT_BATCHES_LIST_TABLE_COLUMN_CREATOR: "Created by",
  KIT_BATCHES_LIST_TABLE_COLUMN_ACTIVITY: "Status",
  KIT_BATCHES_LIST_ACTIVE: "Active",
  KIT_BATCHES_LIST_INACTIVE: "Inactive",
  KIT_BATCHES_LIST_MENU_BUTTON_LABEL: "Actions for the kit batch",
  KIT_BATCHES_LIST_MENU_ACTIVATE: "Activate",
  KIT_BATCHES_LIST_MENU_DEACTIVATE: "Deactivate",
  KIT_BATCHES_LIST_MENU_UPDATE: "Edit",
  KIT_BATCHES_LIST_MENU_DELETE: "Delete",
  KIT_BATCHES_LIST_ACTIVATE_SUCCESS_TOAST_MESSAGE:
    "Kit batch successfully activated",
  KIT_BATCHES_LIST_ACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the activation of the kit batch. Please retry.",
  KIT_BATCHES_LIST_DEACTIVATE_SUCCESS_TOAST_MESSAGE:
    "Kit batch successfully deactivated",
  KIT_BATCHES_LIST_DEACTIVATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the deactivation of the kit batch. Please retry.",
  /* KIT_BATCHES_LIST_DELETION_MODAL */
  KIT_BATCHES_LIST_DELETION_MODAL_TITLE:
    "Removal of the kit batch\n'{{kitBatchName}}'",
  KIT_BATCHES_LIST_DELETION_MODAL_SUBTITLE:
    "Are you sure you want to remove the kit batch '{{kitBatchName}}' ?",
  KIT_BATCHES_LIST_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Remove the kit batch",
  KIT_BATCHES_LIST_DELETION_SUCCESS_TOAST_MESSAGE:
    "Kit batch removal successful",
  KIT_BATCHES_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the removal of the kit batch. Please retry.",
  /* KIT BATCHES EDIT PAGE */
  KIT_BATCHES_EDITION_TITLE_DESCRIPTION: "Edit kit batch '{{kitBatchName}}'",
  KIT_BATCHES_EDITION_FORM_SUBMIT_BUTTON_LABEL: "Edit kit batch",
  /* KIT BATCHES DETAILS PAGE */
  KIT_BATCHES_DETAILS_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Kit batch not found. Back to kit batches list.",
  KIT_BATCHES_DETAILS_TITLE_DESCRIPTION: "Kit batch {{kitBatchName}}",
  KIT_BATCHES_DETAILS_INFORMATIONS_TITLE: "Informations",
  KIT_BATCHES_DETAILS_INFORMATIONS_SUBTITLE:
    "Main informations about the kit batch",
  KIT_BATCHES_DETAILS_BATCH_NUMBER_LABEL: "Kit batch number",
  KIT_BATCHES_DETAILS_ANALYSIS_LABEL: "Related analysis",
  KIT_BATCHES_DETAILS_EXPIRY_LABEL: "Expiry date",
  KIT_BATCHES_DETAILS_CREATION_LABEL: "Creation",
  KIT_BATCHES_DETAILS_CREATION_DATE_VALUE: "The {{- date}}",
  KIT_BATCHES_DETAILS_CREATION_VALUE: "The {{- date}}, by {{user}}",
  KIT_BATCHES_DETAILS_LAST_MODIFICATION_LABEL: "Last modification",
  KIT_BATCHES_DETAILS_LAST_MODIFICATION_DATE_VALUE: "The {{- date}}",
  KIT_BATCHES_DETAILS_LAST_MODIFICATION_VALUE: "The {{- date}}, by {{user}}",
  KIT_BATCHES_DETAILS_TARGETS_TITLE: "Targets related to the kit batch",
  KIT_BATCHES_DETAILS_TARGETS_DESCRIPTION:
    "For each target, find here the minimum and maximum acceptance boundary for the validation of controls for this batch",
  KIT_BATCHES_DETAILS_NO_TARGET: "No bound found",
  KIT_BATCHES_DETAILS_THRESHOLDS_LOWER_THRESHOLD: "Min. bound",
  KIT_BATCHES_DETAILS_THRESHOLDS_LOWER_THRESHOLD_BADGE_LABEL:
    "Min. {{defined}}/{{expected}}",
  KIT_BATCHES_DETAILS_THRESHOLDS_UPPER_THRESHOLD: "Max. bound",
  KIT_BATCHES_DETAILS_THRESHOLDS_UPPER_THRESHOLD_BADGE_LABEL:
    "Max. {{defined}}/{{expected}}",
  KIT_BATCHES_DETAILS_THRESHOLDS_NOT_DEFINED: "Not defined",
  KIT_BATCHES_DETAILS_EDIT_BUTTON: "Edit kit batch",
  /* LOGIN */
  LOGIN_TITLE_DESCRIPTION: "Connection to Geeng",
  LOGIN_WELCOME_TITLE: "Nice to see you again",
  LOGIN_WELCOME_SUBTITLE: "Enter your identifier and\npassword",
  LOGIN_LANGUAGE_SELECTOR_LABEL: "Select the language of your choice",
  LOGIN_FORM_ERROR_INVALID_PASSWORD_OR_IDENTIFIER:
    "Invalid identifier or password",
  LOGIN_FORM_IDENTIFIER_LABEL: "Identifier",
  LOGIN_FORM_IDENTIFIER_ERROR_REQUIRED: "Identifier is required",
  LOGIN_FORM_PASSWORD_LABEL: "Password",
  LOGIN_FORM_PASSWORD_ERROR_REQUIRED: "Password is required",
  LOGIN_FORM_SUBMIT_BUTTON: "Log in",
  LOGIN_FORGOTTEN_PASSWORD_BUTTON: "Forgot password ?",
  /* LOGO */
  LOGO_APP_TITLE: "Geeng",
  LOGO_CUSTOMER: "CHU of Rennes",
  title: "Welcome to As We Care",
  /* LOGOUT */
  LOGOUT_TOKEN_EXPIRED_TOAST_MESSAGE:
    "Your session has expired, you need to login again.",
  LOGOUT_SUCCESSFUL_TOAST_MESSAGE: "You are now logged out",
  /* MAIN APP SIDEBAR */
  MAIN_APP_SIDEBAR_ITEMS_DASHBOARD: "Dashboard",
  MAIN_APP_SIDEBAR_ITEMS_APPLICATION_DPCR: "DPCR",
  MAIN_APP_SIDEBAR_ITEMS_NOTIFICATIONS: "Notifications",
  /* MATH FIELD */
  MATH_FIELD_COPIED: "Copied formula",
  MATH_FIELD_MODAL_VARIABLES_TITLE: "Variables",
  MATH_FIELD_MODAL_VARIABLES_SEARCH_LABEL: "Search a variable name",
  MATH_FIELD_VARIABLES_TABS_LABEL:
    "Choose between raw variables taken directly from the result file and calculated variables defined by the user during the analysis creation run",
  MATH_FIELD_RAW_VARIABLES: "Raw variables",
  MATH_FIELD_CALCULATED_VARIABLES: "Calculated variables",
  MATH_FIELD_NO_VARIABLE_FOUND: "No variable found",
  MATH_FIELD_PLACEHOLDER: "\\mathrm{Enter\\ or\\ paste\\ your\\ formula}",
  /* MATRICES */
  MATRICES_CF_DNA: "cf-DNA",
  MATRICES_F_DNA: "f-DNA",
  /* QUALITY CONTROL RESULT */
  QUALITY_CONTROL_RESULT_PASS_LABEL: "Compliant",
  QUALITY_CONTROL_RESULT_FAIL_LABEL: "Non Compliant",
  QUALITY_CONTROL_RESULT_WARNING_LABEL: "To watch",
  /* RUNS */
  /* RUNS STATUS */
  RUN_STATUS_VALIDATED: "Validated",
  RUN_STATUS_IN_PROGRESS: "In progress",
  RUN_STATUS_READY: "Available",
  RUN_STATUS_WAITING: "Waiting",
  RUN_STATUS_FAILED: "Error",
  RUN_STATUS_PROCESSING: "Processing",
  RUN_STATUS_QUEUED: "Queued",
  /* RUNS QC VALIDTION STATUS */
  RUN_QUALITY_CONTROL_VALIDATION_DONE: "Validation des Quality Controls done",
  RUN_QUALITY_CONTROL_VALIDATION_TODO:
    "Validation des Quality Controls to be carried out",
  /* RUNS CONTROLS TYPE */
  TYPE_BLANK_CONTROL: "Blank control",
  TYPE_BLANK_CONTROL_SHORT: "TB",
  TYPE_NEG_CONTROL: "Negative control",
  TYPE_NEG_CONTROL_SHORT: "TN",
  TYPE_POS_CONTROL: "Positive control",
  TYPE_POS_CONTROL_SHORT: "TP",
  QC_CONTROLS_TOOLTIP: "Result for the quality control {{type}} : {{result}}",
  QC_CONTROLS_TOOLTIP_PASS: "compliant",
  QC_CONTROLS_TOOLTIP_FAIL: "non-compliant",
  QC_CONTROLS_TOOLTIP_UNKNOWN: "unknown",
  QC_CONTROLS_TOOLTIP_ERROR:
    "An error has occurred during the calculation of the result of this Quality Control.",
  QC_CONTROLS_TOOLTIP_TRUE_zero: "compliant : {{count}} positive partition",
  QC_CONTROLS_TOOLTIP_TRUE_one: "compliant : {{count}} positive partition",
  QC_CONTROLS_TOOLTIP_TRUE_other: "compliant : {{count}} positive partitions",
  QC_CONTROLS_TOOLTIP_FALSE_zero:
    "non-compliant : {{count}} positive partition",
  QC_CONTROLS_TOOLTIP_FALSE_one: "non-compliant : {{count}} positive partition",
  QC_CONTROLS_TOOLTIP_FALSE_other:
    "non-compliant : {{count}} positive partitions",
  /* RUNS COMMENTS MODAL*/
  RUNS_COMMENTS_MODAL_MAIN_INFORMATIONS: "Main informations about the run",
  RUNS_COMMENTS_MODAL_RUN_NAME: "Run name",
  RUNS_COMMENTS_MODAL_ANALYSIS_NAME: "Used analysis",
  RUNS_COMMENTS_MODAL_ADD_COMMENT_SUCCESS_TOAST_MESSAGE:
    "Comment added successfully",
  RUNS_COMMENTS_MODAL_ADD_COMMENT_ERROR_TOAST_MESSAGE:
    "An error has occurred during the comment add. Please retry.",
  /* RUNS LIST */
  RUNS_LIST_TITLE_DESCRIPTION: "Runs list",
  RUNS_LIST_TITLE: "Runs list",
  RUNS_LIST_ADD_NEW_SERIE: "Add series",
  RUNS_LIST_TABLE_HEADER_TITLE: "All our runs",
  RUNS_LIST_TABLE_HEADER_BADGE_zero: "{{count}} Series",
  RUNS_LIST_TABLE_HEADER_BADGE_one: "{{count}} Series",
  RUNS_LIST_TABLE_HEADER_BADGE_other: "{{count}} Series",
  RUNS_LIST_TABLE_HEADER_FILTER_SERIES_LABEL: "Series",
  RUNS_LIST_TABLE_HEADER_FILTER_SERIES_NO_OPTION_MESSAGE: "No series found",
  RUNS_LIST_TABLE_HEADER_FILTER_RUN_LABEL: "Run",
  RUNS_LIST_TABLE_HEADER_FILTER_RUN_NO_OPTION_MESSAGE: "No run found",
  RUNS_LIST_TABLE_HEADER_FILTER_ANALYSIS_LABEL: "Analysis",
  RUNS_LIST_TABLE_HEADER_FILTER_ANALYSIS_NO_OPTION_MESSAGE: "No analysis found",
  RUNS_LIST_TABLE_HEADER_FILTER_STATUS_LABEL: "Status",
  RUNS_LIST_TABLE_HEADER_FILTER_DATE_LABEL: "Date",
  RUNS_LIST_TABLE_FILTER_RESET_LABEL: "Reset filters",
  RUNS_LIST_TABLE_COLUMN_SERIE: "Series",
  RUNS_LIST_TABLE_COLUMN_SERIE_MISSING_DATA_TOOLTIP_zero:
    "This series is still waiting for {{count}} sample analysis results in relation to the data in the samplesheets",
  RUNS_LIST_TABLE_COLUMN_SERIE_MISSING_DATA_TOOLTIP_one:
    "This series is still waiting for {{count}} sample analysis results in relation to the data in the samplesheets",
  RUNS_LIST_TABLE_COLUMN_SERIE_MISSING_DATA_TOOLTIP_other:
    "This series is still waiting for {{count}} samples analysis results in relation to the data in the samplesheets",
  RUNS_LIST_TABLE_COLUMN_SERIE_FORCED_MISSING_DATA_TOOLTIP_zero:
    "This series has been forced, {{count}} sample analysis results is missing in relation to the data in the samplesheets",
  RUNS_LIST_TABLE_COLUMN_SERIE_FORCED_MISSING_DATA_TOOLTIP_one:
    "This series has been forced, {{count}} sample analysis results is missing in relation to the data in the samplesheets",
  RUNS_LIST_TABLE_COLUMN_SERIE_FORCED_MISSING_DATA_TOOLTIP_other:
    "This series has been forced, {{count}} sample analysis results are missing in relation to the data in the samplesheets",
  RUNS_LIST_TABLE_COLUMN_SERIE_TOOLTIP: "Number of treated samples",
  RUNS_LIST_TABLE_COLUMN_RUNS: "Runs",
  RUNS_LIST_TABLE_COLUMN_RUNS_TOOLTIP: "Number of run samples",
  RUNS_LIST_TABLE_COLUMN_ANALYSIS: "Analysis",
  RUNS_LIST_TABLE_COLUMN_STATE: "Status",
  RUNS_LIST_TABLE_COLUMN_DATE: "Date",
  RUNS_LIST_TABLE_COLUMN_EDIT_RUN_LINK: "See more details about run {{runId}}",
  /* SAMPLE DETAILS */
  SAMPLE_DETAILS_SAMPLE_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Sample not found. Back to series' samples list.",
  SAMPLE_DETAILS_BREADCRUMBS_BACK_LABEL: "Back to list",
  SAMPLE_DETAILS_BREADCRUMBS_RIGHT_COMPONENT_LABEL_NEXT_SAMPLE: "Next sample",
  SAMPLE_DETAILS_BREADCRUMBS_RIGHT_COMPONENT_LABEL_BACK_TO_LIST: "Back to list",
  SAMPLE_DETAILS_TITLE_DESCRIPTION: "Details of sample {{sampleId}}",
  SAMPLE_DETAILS_TITLE: "Sample",
  SAMPLE_DETAILS_TITLE_STATUS: "Status :",
  SAMPLE_DETAILS_TITLE_COMMENTS_zero: "{{count}} comment",
  SAMPLE_DETAILS_TITLE_COMMENTS_one: "{{count}} comment",
  SAMPLE_DETAILS_TITLE_COMMENTS_other: "{{count}} comments",
  SAMPLE_DETAILS_HEADER_PATHOLOGY: "Pathology",
  SAMPLE_DETAILS_HEADER_SAMPLE_TYPE: "S. Type",
  SAMPLE_DETAILS_HEADER_CELL_QUANTITY: "Cell V.",
  SAMPLE_DETAILS_HEADER_ANALYSES: "Analyses",
  SAMPLE_DETAILS_ACTION_BUTTON_LABEL: "Actions",
  SAMPLE_DETAILS_ACTION_MENU_EXPORT: "Export",
  SAMPLE_DETAILS_ACTION_MENU_EXPORT_DISABLED_TOOLTIP:
    "Select at least one target to export",
  SAMPLE_DETAILS_EXPORT_MODAL_TITLE: "Export of data",
  SAMPLE_DETAILS_EXPORT_MODAL_INFORMATIONS: "Informations",
  SAMPLE_DETAILS_EXPORT_MODAL_INFORMATIONS_SAMPLE_ID: "Sample ID",
  SAMPLE_DETAILS_EXPORT_MODAL_ANALYSIS_TARGETS_COUNT_zero: "{{count}} target",
  SAMPLE_DETAILS_EXPORT_MODAL_ANALYSIS_TARGETS_COUNT_one: "{{count}} target",
  SAMPLE_DETAILS_EXPORT_MODAL_ANALYSIS_TARGETS_COUNT_other: "{{count}} targets",
  SAMPLE_DETAILS_EXPORT_MODAL_SUBMIT_LABEL: "Export",
  SAMPLE_DETAILS_EXPORT_SUCCESS_TOAST_MESSAGE: "Export successfully done",
  SAMPLE_DETAILS_EXPORT_ERROR_TOAST_MESSAGE:
    "An error has occurred during the data export. Please retry.",
  SAMPLE_DETAILS_TABLE_COLUMN_ANALYSIS: "Analysis",
  SAMPLE_DETAILS_TABLE_COLUMN_ANALYSIS_STATUS: "Status",
  SAMPLE_DETAILS_TABLE_COLUMN_QUALITY_CONTROL: "QC",
  SAMPLE_DETAILS_TABLE_COLUMN_QUALITY_CONTROL_VALIDATION_PASS: "Pass",
  SAMPLE_DETAILS_TABLE_COLUMN_QUALITY_CONTROL_VALIDATION_NOT_PASS: "Not pass",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_CHAMBER: "Chamber (part.)",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_SAMPLE: "Sample (part.)",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_DNA: "DNA (ng)",
  SAMPLE_DETAILS_TABLE_COLUMN_TARGET: "Target",
  SAMPLE_DETAILS_TABLE_COLUMN_BLANK_CONTROL: "TB",
  SAMPLE_DETAILS_TABLE_COLUMN_NEG_CONTROL: "TN",
  SAMPLE_DETAILS_TABLE_COLUMN_POS_CONTROL: "TP",
  SAMPLE_DETAILS_TABLE_COLUMN_PARTITIONS: "PART.",
  SAMPLE_DETAILS_TABLE_COLUMN_VAF: "VAF",
  SAMPLE_DETAILS_TABLE_COLUMN_SENSITIVITY: "Max Sens.",
  SAMPLE_DETAILS_TABLE_COLUMN_UNCERTAINTY: "Uncert.",
  SAMPLE_DETAILS_TABLE_COLUMN_INTERPRETATION: "Interpretation",
  SAMPLE_DETAILS_TABLE_COLUMN_FLAGGED_TOOLTIP_DISABLED:
    "Impossible to declare a non-mutated target as a variant of interest",
  SAMPLE_DETAILS_TABLE_PREVALIDATE_RUN_RESULT: "Pre-validate",
  SAMPLE_DETAILS_VALIDATE_MODAL_FORM_COMMENT_LABEL: "Comment",
  SAMPLE_DETAILS_VALIDATE_BUTTON: "Validate",
  SAMPLE_DETAILS_VALIDATED: "Validated",
  SAMPLE_DETAILS_VALIDATED_DATE_DATA: "Validated on {{date}}",
  SAMPLE_DETAILS_VALIDATED_FULL_DATA: "Validated on {{date}}, by {{user}}",
  SAMPLE_DETAILS_PREVALIDATED: "Pre-validated",
  SAMPLE_DETAILS_PREVALIDATED_DATE_DATA: "Pre-validated on {{date}}",
  SAMPLE_DETAILS_PREVALIDATED_FULL_DATA:
    "Pre-validated on {{date}}, by {{user}}",
  SAMPLE_DETAILS_VALIDATE_SUCCESS_TOAST_MESSAGE:
    "Sample validated successfully",
  SAMPLE_DETAILS_VALIDATE_ERROR_TOAST_MESSAGE:
    "An error has occurred during the validation of the sample. Please retry.",
  SAMPLE_DETAILS_PREVALIDATE_RUN_SUCCESS_TOAST_MESSAGE:
    "Analysis pre-validated successfully",
  SAMPLE_DETAILS_PREVALIDATE_RUN_ERROR_TOAST_MESSAGE:
    "An error has occurred during the pre-validation of the analysis. Please retry.",
  SAMPLE_DETAILS_UPDATE_RUN_SUCCESS_TOAST_MESSAGE:
    "Information updated successfully",
  SAMPLE_DETAILS_UPDATE_RUN_ERROR_TOAST_MESSAGE:
    "An error has occurred during the update. Please retry.",
  SAMPLE_DETAILS_ADD_COMMENT_SUCCESS_TOAST_MESSAGE:
    "Comment added successfully",
  SAMPLE_DETAILS_ADD_COMMENT_ERROR_TOAST_MESSAGE:
    "An error has occurred during the comment add. Please retry.",
  /* SAMPLE DETAILS VALIDATION MODAL */
  SAMPLE_DETAILS_VALIDATION_CONFIRMATION_MODAL_TITLE: "Sample validation",
  SAMPLE_DETAILS_VALIDATION_CONFIRMATION_MODAL_SUBTITLE:
    "You are about to validate the sample",
  SAMPLE_DETAILS_VALIDATION_CONFIRMATION_MODAL_QUESTION:
    "Are you sure you want to continue with validation ?",
  /* SAMPLE INTERPRETATION */
  UNKNOWN: "Not interpreted",
  NON_CONTRIBUTIVE: "Not contributive",
  UNMUTATED: "Not mutated",
  MUTATED: "Mutated",
  /* SAMPLE POSITIVITY */
  SAMPLE_POSITIVITY_CANDIDATE: "Candidate",
  SAMPLE_POSITIVITY_POSITIVE: "Positive",
  /* SAMPLE PRIORITY */
  SAMPLE_PRIORITY_LOW: "Low",
  SAMPLE_PRIORITY_NORMAL: "Routine",
  SAMPLE_PRIORITY_HIGH: "Urgent",
  /* SAMPLE STATUS */
  SAMPLE_STATUS_READY: "To do",
  SAMPLE_STATUS_PROCESSING: "In progress",
  SAMPLE_STATUS_VALIDATED: "Validated",
  SAMPLE_STATUS_ERROR: "Error",
  /* SAMPLE TYPE */
  SAMPLE_TYPE_F_DNA: "free DNA",
  SAMPLE_TYPE_F_DNA_SHORT: "F",
  SAMPLE_TYPE_CF_DNA: "cell-free DNA",
  SAMPLE_TYPE_CF_DNA_SHORT: "CF",
  /* SAMPLESHEETS LIST */
  SAMPLESHEETS_LIST_CARD_MENU_BUTTON_LABEL: "Actions for the samplesheet",
  SAMPLESHEETS_LIST_CARD_MENU_DELETE: "Remove",
  SAMPLESHEETS_LIST_CARD_MENU_DOWNLOAD: "Download",
  SAMPLESHEETS_LIST_CARD_DELETION_MODAL_TITLE: "Removal of the samplesheet",
  SAMPLESHEETS_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Are you sure you want to remove the samplesheet\n{{- samplesheetName}} ?",
  SAMPLESHEETS_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON:
    "Remove the samplesheet",
  SAMPLESHEETS_LIST_DELETION_SUCCESS_TOAST_MESSAGE:
    "Samplesheet deleted successfully",
  SAMPLESHEETS_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the removal of the samplesheet. Please retry.",
  /* SERIES CREATION */
  SERIES_CREATION_TITLE_DESCRIPTION: "Series creation",
  SERIES_CREATION_TITLE: "Series creation",
  SERIES_CREATION_SUBTITLE:
    "Give the series a unique name to identify it easily. You will then be able to load its samplesheets files and after, Runs results files.",
  SERIES_CREATION_DESCRIPTION: "Description",
  SERIES_CREATION_FORM_INFORMATIONS_TITLE: "Informations",
  SERIES_CREATION_FORM_AUTHOR: "Author",
  SERIES_CREATION_FORM_DATE: "Date",
  SERIES_CREATION_FORM_IDENTIFIER_LABEL: "Series name",
  SERIES_CREATION_FORM_IDENTIFIER_PLACEHOLDER: "Enter the series name",
  SERIES_CREATION_FORM_SUBMIT: "Create the series",
  SERIES_CREATION_FORM_SUCCESS_TOAST: "Series successfully created",
  /* SERIE DETAILS */
  SERIES_DETAILS_TITLE: "Series",
  SERIES_DETAILS_TITLE_DESCRIPTION: "Details of the series '{{serieName}}'",
  SERIES_DETAILS_SERIE_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Series not found. Back to series list.",
  SERIES_DETAILS_RUN_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Run not found for the series. Back to series' details page.",
  /* SERIE DETAILS NO RESULTS*/
  SERIES_DETAILS_NO_RESULTS_TITLE: "Series details",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_TITLE: "Samplesheets",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_IMPORT_BUTTON_LABEL:
    "Add Samplesheets",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_SAMPLESHEETS_NB_zero:
    "Samplesheet",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_SAMPLESHEETS_NB_one:
    "Samplesheet",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_SAMPLESHEETS_NB_other:
    "Samplesheets",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_ADD_DESCRIPTION:
    "No Samplesheet transmitted for this series.",
  SERIES_DETAILS_NO_RESULTS_RESULTS_CARD_TITLE: "Results file",
  SERIES_DETAILS_NO_RESULTS_RESULTS_CARD_RESULTS_NB: "Results file",
  SERIES_DETAILS_NO_RESULTS_RESULTS_CARD_ADD_DESCRIPTION:
    "No results file transmitted for this series.",
  SERIES_DETAILS_NO_RESULTS_RUNS_RESULTS_IMPORT_BUTTON_LABEL: "Add results",
  SERIES_DETAILS_REIMPORT_RUNS_RESULTS_BUTTON_LABEL: "Re-import results",
  /* SERIE DETAILS RUN IN TREATMENT */
  SERIES_DETAILS_RUN_TREATMENT_TITLE: "Run result file in treatment",
  /* SERIE DETAILS CONTROL VALIDATION*/
  SERIES_DETAILS_CONTROL_VALIDATION_TITLE: "Controls validation",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_LABEL: "Runs of the series",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_SAMPLES_LABEL_zero: "Sample",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_SAMPLES_LABEL_one: "Sample",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_SAMPLES_LABEL_other: "Samples",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TITLE: "Analysis, Kit & Chip",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_ANALYSIS_COLUMN_TITLE:
    "Analysis",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_SUPPLIER_COLUMN_TITLE:
    "Supplier",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_CHIP_COLUMN_TITLE:
    "Chip",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_KIT_COLUMN_TITLE: "Kit",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_TARGETS_COLUMN_TITLE:
    "Targets",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_TITLE:
    "Global Quality Control",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SUBTITLE:
    "Chip verification",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_zero: "{{count}} Chip",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_one: "{{count}} Chip",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_other:
    "{{count}} Chips",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_INDEX:
    "Chip n°{{chipIndex}}",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_VALIDATION_TOOLTIP:
    "Validate or not the Quality Controls for the chip n°{{chipIndex}}",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_TABLE_COLUMN_HEADER_ID:
    "ID",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART1_zero:
    "Sample {{sample}} : {{count}} partition for this sample",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART1_one:
    "Sample {{sample}} : {{count}} partition for this sample",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART1_other:
    "Sample {{sample}} : {{count}} partitions for this sample",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART2_zero:
    "{{count}} expected partition to satisfy the chip quality control",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART2_one:
    "{{count}} expected partition to satisfy the chip quality control",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART2_other:
    "{{count}} expected partitions to satisfy the chip quality control",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_VALIDATION_SUCCESS_TOAST_MESSAGE:
    "Chip quality control updated successfully",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_VALIDATION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the update of the chip quality control. Please retry.",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_UPDATE_SAMPLE_RESULT_SUCCESS_TOAST_MESSAGE:
    "Quality control result of the sample {{sampleId}} updated successfully",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_UPDATE_SAMPLE_RESULT_ERROR_TOAST_MESSAGE:
    "An error has occurred during the update of the quality control result of the sample {{sampleId}}. Please retry.",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TITLE:
    "Control Quality of Witnesses",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_CHIP_zero:
    "{{count}} Witness",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_CHIP_one:
    "{{count}} Witness",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_CHIP_other:
    "{{count}} Witnesses",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_MISSING_CONTROL_zero:
    "There are no control results missing from the samplesheet for this run. You can now validate this run's controls.",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_MISSING_CONTROL_one:
    "The results of a control for this run are missing from the samplesheet ({{missing}}). You can still validate the controls present for this run.",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_MISSING_CONTROL_other:
    "The results of {{count}} controls for this run are missing from the samplesheet ({{missing}}). You can still validate the controls present for this run.",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_MISSING_CONTROL_VALIDATED_zero:
    "There are no control results missing from the samplesheet for this run.",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_MISSING_CONTROL_VALIDATED_one:
    "The results of a control for this run are missing from the samplesheet ({{missing}}).",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_MISSING_CONTROL_VALIDATED_other:
    "The results of {{count}} controls for this run are missing from the samplesheet ({{missing}}).",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_TYPE_COLUMN_TITLE:
    "Control type",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_BATCH_NUMBER:
    "Batch N° : {{batchNumber}}",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_ID_COLUMN_TITLE:
    "ID",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_PARTITIONS_NB_COLUMN_TITLE:
    "Partitions nb",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_UPDATE_SAMPLE_RESULT_SUCCESS_TOAST_MESSAGE:
    "Quality control result of the witness {{sampleId}} updated successfully",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_UPDATE_SAMPLE_RESULT_ERROR_TOAST_MESSAGE:
    "An error has occurred during the update of the quality control result of the witness {{sampleId}}. Please retry.",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON:
    "Validate controls of the run",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_FORCE_BUTTON:
    "Force validation of run controls",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON_TOOLTIP_INFOS_one:
    "The following status has not been defined:",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON_TOOLTIP_INFOS_other:
    "The following statuses have not been defined:",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON_TOOLTIP_INFO_CHIP:
    "Chip {{chipId}}",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON_TOOLTIP_INFO_CONTROL:
    "Control {{type}} (Batch n° {{batchNumber}})",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_TITLE:
    "Validation of the run's quality controls",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_SUBTITLE:
    "You are about to validate the run's quality controls. You can leave a comments to provide further information.",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_FORCE_SUBTITLE:
    "As a reminder, at least one control is missing from the samplesheet content.",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_SUMMARY_INFORMATIONS:
    "Informations",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_ANALYSIS: "Analysis",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CHIPS_zero:
    "Chip number",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CHIPS_one: "Chip number",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CHIPS_other:
    "Chip number",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CONTROLS_zero:
    "Witness number",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CONTROLS_one:
    "Witness number",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CONTROLS_other:
    "Witnesses number",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_VALIDATION_DATE:
    "Validation date",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_OPERATOR: "By",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_COMMENTS: "Comments",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_SUBMIT:
    "Validate the controls of the run",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_FORCE_SUBMIT:
    "Force validation of run controls",
  SERIES_DETAILS_CONTROL_VALIDATED:
    "Quality controls validated on {{- date}} by {{by}}",
  SERIES_DETAILS_CONTROL_VALIDATED_ANONYMOUS:
    "Quality controls validated on {{- date}}",
  SERIES_DETAILS_CONTROL_VALIDATION_SUCCESS_TOAST_MESSAGE:
    "Validation of the run's quality controls done successfully",
  SERIES_DETAILS_CONTROL_VALIDATION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the validation of the run's quality controls. Please retry.",
  /* SERIES DETAIL MISSING SAMPLES */
  SERIES_DETAILS_MISSING_SAMPLES_TITLE: "Incomplete series",
  SERIES_DETAILS_MISSING_SAMPLES_SUBTITLE_PART1_zero:
    "The series currently contains {{count}} sample results.",
  SERIES_DETAILS_MISSING_SAMPLES_SUBTITLE_PART1_one:
    "The series currently contains {{count}} sample results.",
  SERIES_DETAILS_MISSING_SAMPLES_SUBTITLE_PART1_other:
    "The series currently contains {{count}} sample results.",
  SERIES_DETAILS_MISSING_SAMPLES_SUBTITLE_PART2_zero:
    "According to the data contained in the samplesheets, {{count}} result is missing.",
  SERIES_DETAILS_MISSING_SAMPLES_SUBTITLE_PART2_one:
    "According to the data contained in the samplesheets, {{count}} result is missing.",
  SERIES_DETAILS_MISSING_SAMPLES_SUBTITLE_PART2_other:
    "According to the data contained in the samplesheets, {{count}} results are missing.",
  SERIES_DETAILS_MISSING_SAMPLES_QUESTION:
    "Would you like to force this series and access the results of previously loaded samples?",
  SERIES_DETAILS_MISSING_SAMPLES_FORCE_SUBMIT_BUTTON: "Force the series",
  SERIES_DETAILS_FORCING_SUCCESS_TOAST_MESSAGE: "Series successfully forced",
  SERIES_DETAILS_FORCING_ERROR_TOAST_MESSAGE:
    "An error has occurred during the forcing of the series. Please retry.",
  /* SERIES DETAIL SAMPLES LIST */
  SERIES_DETAILS_SAMPLES_LIST_TITLE: "Series",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE: "All samples",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE_COUNT_zero: "{{count}} Sample",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE_COUNT_one: "{{count}} Sample",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE_COUNT_other: "{{count}} Samples",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_SAMPLE_ID_LABEL: "Sample ID",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_SAMPLE_ID_NO_OPTION_MESSAGE:
    "No Sample ID found",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_PATHOLOGY_LABEL: "Pathology",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_PATHOLOGY_NO_OPTION_MESSAGE:
    "Aucun pathology found",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_STATUS_LABEL: "Status",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_RESET_LABEL: "Reset filters",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_ID: "Sample ID",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS:
    "Sample informations",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS_SAMPLE_ID:
    "Sample ID",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS_PATHOLOGY:
    "Pathology",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS_ANALYSES:
    "Analyses",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_PRIORITY: "Priority",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_PATHOLOGY: "Pathology",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_SAMPLE_TYPE: "S. Type",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_CELL_QUANTITY: "Cell V.",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_ANALYSES: "Analyses",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_ANALYSES_TOOLTIP:
    "Interpretation for analysis {{analysisName}} : {{interpretation}}",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_POSITIVITY: "Positivity",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_STATUS: "Status",
  /* SERIES INCOMPLETE LIST */
  SERIES_INCOMPLETE_LIST_TITLE: "Upload results : incomplete series list",
  SERIES_INCOMPLETE_LIST_DESCRIPTION:
    "Select an incomplete series in the below list to add runs results to it",
  SERIES_INCOMPLETE_LIST_TABLE_HEADER_TITLE: "All our incomplete series",
  /* SERIES LIST */
  BACK_TO_SERIES_LIST: "Back to series list",
  SERIES_LIST_TITLE_DESCRIPTION: "Series list",
  SERIES_LIST_TITLE: "Series list",
  SERIES_LIST_TABLE_HEADER_TITLE: "All our series",
  SERIES_LIST_TABLE_HEADER_BADGE_zero: "{{count}} series",
  SERIES_LIST_TABLE_HEADER_BADGE_one: "{{count}} series",
  SERIES_LIST_TABLE_HEADER_BADGE_other: "{{count}} series",
  SERIES_LIST_TABLE_HEADER_FILTER_BUTTON: "Filter",
  SERIES_LIST_TABLE_HEADER_FILTER_DATE_LABEL: "Filter by date",
  SERIES_LIST_TABLE_HEADER_FILTER_DATE_PLACEHOLDER: "Select a date",
  SERIES_LIST_CARD_DATE_LABEL: "Date",
  SERIES_LIST_CARD_EXPECTED_SAMPLES_LABEL: "Expected samples",
  SERIES_LIST_CARD_TREATED_SAMPLES_LABEL: "Treated samples",
  SERIES_LIST_CARD_MENU_BUTTON_LABEL: "Actions for the series",
  SERIES_LIST_CARD_MENU_ADD_NEW_SAMPLESHEET: "Add samplesheet",
  SERIES_LIST_CARD_MENU_DELETE: "Delete",
  SERIES_LIST_CARD_SEE_DETAILS_LINK: "See in details",
  SERIES_LIST_CARD_SELECT_SERIES: "Select this series",
  SERIES_LIST_CARD_DELETION_MODAL_TITLE:
    "Removal of the series\n'{{serieName}}'",
  SERIES_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Are you sure you want to remove the series '{{serieName}}' ?",
  SERIES_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Remove the series",
  SERIES_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Series deleted successfully",
  SERIES_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "An error has occurred during the removal of the series. Please retry.",
  /* SERIES RUNS RESULTS IMPORT */
  SERIES_RUNS_RESULTS_IMPORT_TITLE: "Import results files",
  SERIES_RUNS_RESULTS_IMPORT_TITLE_BADGE: "Series {{serieName}}",
  SERIES_RUNS_RESULTS_IMPORT_TITLE_MISSING_DATA:
    "Impossible to retrieve data of the selected series.",
  SERIES_RUNS_RESULTS_DESCRIPTION: "Description",
  SERIES_RUNS_RESULTS_IMPORT_SUBTITLE:
    "Drop or select results files for this series. For each file, you can rename it and then link it with the kit batch number used for controls and the analysis used.",
  SERIES_RUNS_RESULTS_IMPORT_TABS_LABEL:
    "Informations and summary of the results import",
  SERIES_RUNS_RESULTS_IMPORT_TABS_INFORMATIONS: "Informations",
  SERIES_RUNS_RESULTS_IMPORT_TABS_SUMMARY: "Summary",
  SERIES_RUNS_RESULTS_IMPORT_INFORMATIONS: "Fill informations",
  SERIES_RUNS_RESULTS_IMPORT_FORM_IMPORT_ERROR_MESSAGE: "Import failed",
  SERIES_RUNS_RESULTS_IMPORT_FORM_IMPORT_SUCCESSFUL: "File imported",
  SERIES_RUNS_RESULTS_IMPORT_FORM_NAME_LABEL: "Run name",
  SERIES_RUNS_RESULTS_IMPORT_FORM_ANALYSIS_LABEL: "Analysis",
  SERIES_RUNS_RESULTS_IMPORT_FORM_ANALYSIS_PLACEHOLDER: "Analysis",
  SERIES_RUNS_RESULTS_IMPORT_FORM_ANALYSIS_NO_OPTION_MESSAGE:
    "No analysis found",
  SERIES_RUNS_RESULTS_IMPORT_FORM_KIT_BATCH_LABEL: "Kit batch",
  SERIES_RUNS_RESULTS_IMPORT_FORM_KIT_BATCH_PLACEHOLDER: "Kit batch",
  SERIES_RUNS_RESULTS_IMPORT_FORM_KIT_BATCH_NO_OPTION_MESSAGE:
    "No kit batch found",
  SERIES_RUNS_RESULTS_IMPORT_FORM_SUBMIT_BUTTON: "Import",
  SERIES_RUNS_RESULTS_IMPORT_FORM_SEE_SUMMARY: "See summary",
  SERIES_RUNS_RESULTS_OPEN_SERIES: "See series",
  /* SERIES RUNS RESULTS RE-IMPORT MODAL */
  SERIES_RUNS_RESULTS_REIMPORT_TITLE: "Replace run results file",
  SERIES_RUNS_RESULTS_REIMPORT_TITLE_MISSING_DATA:
    "Impossible to retrieve data of the selected series and run.",
  SERIES_RUNS_RESULTS_REIMPORT_TITLE_BADGE:
    "Series {{serieName}} - Run {{runId}}",
  SERIES_RUNS_RESULTS_REIMPORT_SUBTITLE:
    "Drop or select a results file for this run. Remember to check that this is the right file (analysis '{{analysis}}' and kit batch '{{kitBatch}}').",
  SERIES_RUNS_RESULTS_IMPORT_FORM_IMPORT_NB_MAX_FILE_ERROR_MESSAGE_zero:
    "You can only select {{count}} file",
  SERIES_RUNS_RESULTS_IMPORT_FORM_IMPORT_NB_MAX_FILE_ERROR_MESSAGE_one:
    "You can only select {{count}} file",
  SERIES_RUNS_RESULTS_IMPORT_FORM_IMPORT_NB_MAX_FILE_ERROR_MESSAGE_other:
    "You can only select {{count}} files",
  /* SERIES RUNS RESULTS RE-IMPORT CONFIRMATION MODAL */
  SERIES_RUNS_RESULTS_REIMPORT_CONFIRMATION_TITLE: "Run results re-import",
  SERIES_RUNS_RESULTS_REIMPORT_CONFIRMATION_SUBTITLE:
    "You are about to re-import the results of the run. By validating, you will lose the data for this run.",
  SERIES_RUNS_RESULTS_REIMPORT_CONFIRMATION_QUESTION:
    "Are you sure you want to continue importing ?",
  /* SERIES SAMPLESHEETS IMPORT */
  SERIES_SAMPLESHEETS_IMPORT_TITLE: "Import Samplesheet files",
  SERIES_SAMPLESHEETS_IMPORT_TITLE_BADGE: "Series {{serieName}}",
  SERIES_SAMPLESHEETS_IMPORT_TITLE_ADMIN_RIGHTS_NEEDED:
    "You do not have the rights to retrieve user names.",
  SERIES_SAMPLESHEETS_IMPORT_TITLE_MISSING_DATA:
    "Impossible to retrieve data of the selected series.",
  SERIES_SAMPLESHEETS_DESCRIPTION: "Description",
  SERIES_SAMPLESHEETS_IMPORT_SUBTITLE:
    "Drop or select samplesheets files and link them to one or more operators.",
  SERIES_SAMPLESHEETS_IMPORT_TABS_INFORMATIONS: "Informations",
  SERIES_SAMPLESHEETS_IMPORT_TABS_SUMMARY: "Summary",
  SERIES_SAMPLESHEETS_IMPORT_TABS_LABEL:
    "Informations and summary of the samplesheets import",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_OPERATOR_LABEL: "Operator(s)",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_OPERATOR_NO_OPTION:
    "No operator found",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_OPERATOR_PLACEHOLDER:
    "Add operators",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_SUPPORTED_FILES_LABEL:
    "Supported files are only : .xlsx et .xls",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_SELECT_FILES_BUTTON_LABEL:
    "Click to select",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_LABEL: "Samplesheets files",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_INNER_TEXT: "or drop a file directly",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_INNER_SUBTEXT:
    "Supported formats : {{formats}}",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_INNER_BUTTON: "Browse your files",
  SERIES_SAMPLESHEETS_IMPORT_SELECT_OPERATORS: "Select operators",
  SERIES_SAMPLESHEETS_IMPORT_REMOVE_SERIES_QUESTION_zero:
    "Importing the selected file did not work. Would you like to delete this series?",
  SERIES_SAMPLESHEETS_IMPORT_REMOVE_SERIES_QUESTION_one:
    "Importing the selected file did not work. Would you like to delete this series?",
  SERIES_SAMPLESHEETS_IMPORT_REMOVE_SERIES_QUESTION_other:
    "Importing the selected files did not work. Would you like to delete this series?",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SUBMIT_BUTTON: "Import",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SEE_SUMMARY: "See summary",
  SERIES_SAMPLESHEETS_OPEN_UPLOAD_RESULTS_BUTTON: "Drop results",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_IMPORT_ERROR_MESSAGE:
    "Import failed",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_IMPORT_SUCCESSFUL:
    "File imported",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SUCCESS_TOAST_one:
    "Import of samplesheet file successful",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SUCCESS_TOAST_other:
    "Import od {{count}} samplesheets files successful",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_NB_SAMPLES_LABEL: "Samples number",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_NB_SAMPLES_VALUE_zero: "{{count}} Sample",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_NB_SAMPLES_VALUE_one: "{{count}} Sample",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_NB_SAMPLES_VALUE_other:
    "{{count}} Samples",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_ANALYSES: "Analyses",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_ANALYSES_LABEL: "Analyses number",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_ANALYSES_VALUE_zero: "{{count}} Analysis",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_ANALYSES_VALUE_one: "{{count}} Analysis",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_ANALYSES_VALUE_other: "{{count}} Analyses",
  /* SOFTWARES LITS */
  SOFTWARES_LIST_TITLE_DESCRIPTION: "Softwares list",
  SOFTWARES_LIST_TITLE: "Softwares list",
  SOFTWARES_LIST_SUBTITLE: "All softwares",
  SOFTWARES_LIST_SEARCH_SOFTWARE_LABEL: "Search software",
  SOFTWARES_LIST_COUNT_LABEL_zero: "{{count}} software",
  SOFTWARES_LIST_COUNT_LABEL_one: "{{count}} software",
  SOFTWARES_LIST_COUNT_LABEL_other: "{{count}} softwares",
  SOFTWARES_LIST_CONTACT_LABEL:
    "You want to integrate a new software in the application in order to operate new results files ? Contact the Geeng team",
  SOFTWARES_LIST_CONTACT_BUTTON_LABEL: "Contact",
  SOFTWARES_LIST_CARD_IS_ACTIVE: "Active",
  /* WTNESSES */
  POSITIVE_CONTROL_zero: "positive witness",
  POSITIVE_CONTROL_one: "positive witness",
  POSITIVE_CONTROL_other: "positive witnesses",
  NEGATIVE_CONTROL_zero: "negative witness",
  NEGATIVE_CONTROL_one: "negative witness",
  NEGATIVE_CONTROL_other: "negative witnesses",
  BLANK_CONTROL_zero: "blank witness",
  BLANK_CONTROL_one: "blank witness",
  BLANK_CONTROL_other: "blank witnesses",
}
